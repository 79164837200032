// import React, { Fragment, useEffect } from 'react';
// import { Styles, useStyles, PaginStyle } from './style';
// import { Container } from './../../../component';
// import { useTable, usePagination, useRowSelect } from 'react-table';
// import Paginations from '@material-ui/lab/Pagination';
// import { WrapTable } from 'component';
// import { Input, Grid } from './../../../component';
// import { useSelector, useDispatch } from 'react-redux';
// import { setActiveInfromastionPagination, setKeywordInformasi } from '../../../redux/actions/';

// const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
//   const defaultRef = React.useRef();
//   const resolvedRef = ref || defaultRef;

//   React.useEffect(() => {
//     resolvedRef.current.indeterminate = indeterminate;
//   }, [resolvedRef, indeterminate]);

//   return (
//     <Fragment>
//       <input type="checkbox" ref={resolvedRef} {...rest} />
//     </Fragment>
//   );
// });

// const Table = ({ columns, data, pagination, handleSelected, pageCount: controlledPageCount, dataAudience }) => {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//     gotoPage,
//     selectedFlatRows,
//     pageCount,
//     setPageSize,
//     rows,
//     toggleRowSelected,
//     state: { pageIndex, pageSize },
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageIndex: 0 },
//       manualPagination: true, // Tell the usePagination
//       pageCount: controlledPageCount,
//     },
//     usePagination,
//     useRowSelect,
//     hooks => {
//       hooks.visibleColumns.push(columns => [
//         {
//           id: 'selection',
//           Header: ({ getToggleAllRowsSelectedProps }) => (
//             <div>
//               <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
//             </div>
//           ),
//           Cell: ({ row }) => (
//             <div>
//               <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
//             </div>
//           ),
//         },
//         ...columns,
//       ]);
//     },
//   );
//   const [keyword, setkeyword] = React.useState('');

//   const changeKeyword = () => {
//     console.log(keyword, 'keyword');
//     dispatch(setKeywordInformasi(keyword));
//   };

//   useEffect(() => {
//     handleSelected(selectedFlatRows);
//   }, [selectedFlatRows, handleSelected]);

//   useEffect(() => {
//     rows.forEach(({ id, original }) => {
//       if ('isSelectedCustom' in original) {
//         if (original.isSelectedCustom) {
//           toggleRowSelected(id, true);
//         }
//       }
//     });
//     // console.log(rows, 'masuk 123');
//   }, [rows, toggleRowSelected]);

//   return (
//     <Fragment>
//       <Container height={'fit-content'}>
//         <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//           <Input
//             spacing={'12px'}
//             placeholder="Search..."
//             onClick={changeKeyword}
//             onChange={e => setkeyword(e.target.value)}
//           />
//         </div>

//         <Grid />
//         <WrapTable>
//           <table {...getTableProps()}>
//             <thead>
//               {headerGroups.map(headerGroup => (
//                 <tr {...headerGroup.getHeaderGroupProps()}>
//                   {headerGroup.headers.map(column => (
//                     <th {...column.getHeaderProps()}>{column.render('Header')}</th>
//                   ))}
//                 </tr>
//               ))}
//             </thead>
//             <tbody {...getTableBodyProps()} style={{ height: '100%' }}>
//               {page.map((row, i) => {
//                 prepareRow(row);
//                 return (
//                   <tr {...row.getRowProps()}>
//                     {row.cells.map(cell => {
//                       return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
//                     })}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </WrapTable>
//       </Container>
//       <Grid />
//       <PaginStyle>
//         <Paginations
//           className={classes.ul}
//           count={pagination.last_page}
//           variant="outlined"
//           shape="rounded"
//           onChange={(d, i) => {
//             setPageSize(10);
//             dispatch(setActiveInfromastionPagination(i));
//             gotoPage(i - 1);
//           }}
//         />
//       </PaginStyle>
//     </Fragment>
//   );
// };

// function TableAudience({ datas, pagination, handleSelected, dataAudience }) {
//   const columns = React.useMemo(
//     () => [
//       {
//         Header: 'Kode',
//         accessor: 'kode',
//       },
//       {
//         Header: 'Nama',
//         accessor: 'nama',
//       },
//       {
//         Header: 'Alamat',
//         accessor: 'alamat',
//       },
//       ,
//       {
//         Header: 'Kota',
//         accessor: 'kota',
//       },
//       {
//         Header: 'Telepon',
//         accessor: 'telp',
//       },
//     ],
//     [],
//   );

//   return (
//     <Styles>
//       <Table
//         columns={columns}
//         data={datas}
//         pagination={pagination}
//         handleSelected={e => handleSelected(e)}
//         dataAudience={dataAudience}
//       />
//     </Styles>
//   );
// }

// export default TableAudience;

import React, { Fragment } from 'react';
import './Information.css';
import { makeStyles, lighten } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { BiSearch } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveInfromastionPagination, setKeywordInformasi, setInstansiLimit } from '../../../redux/actions/';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'kode', numeric: true, disablePadding: false, label: 'Kode' },
  { id: 'nama', numeric: true, disablePadding: false, label: 'Nama' },
  { id: 'alamat', numeric: true, disablePadding: false, label: 'Alamat' },
  { id: 'kota', numeric: true, disablePadding: false, label: 'Kota' },
  { id: 'telepon', numeric: true, disablePadding: false, label: 'Telepon' },
];

function HeaderTable(props) {
  const { onSelectAllClick, numSelected, rowCount, activePaginate, countMatch } = props;

  return (
    <TableHead className="table-head-hasil-rujukan">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            className="checkTab"
            indeterminate={rowCount > 0 && countMatch < 10}
            checked={rowCount > 0 && countMatch === 10}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

HeaderTable.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = props => {
  const { numSelected } = props;
  return (
    <div className={`head-toolbar-action ${numSelected ? 'active' : ''}`}>
      {numSelected > 0 ? (
        <div className="header-table-action">
          <p>{numSelected} selected</p>
        </div>
      ) : null}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Data Pasien Rujukan', isActive: true },
];
const DataHasilPasien = ({ datas, pagination, activePaginate, setSelected, selected }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [keyword, setkeyword] = React.useState('');

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = datas.map(n => n.kode);
      setSelected([...selected, ...newSelecteds]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, kode) => {
    const selectedIndex = selected.indexOf(kode);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, kode);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setActiveInfromastionPagination(newPage + 1));
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(setInstansiLimit(parseInt(event.target.value, 10)));
    dispatch(setActiveInfromastionPagination(1));
  };

  const changeKeyword = () => {
    console.log(keyword, 'keyword');
    dispatch(setKeywordInformasi(keyword));
  };
  const isSelected = kode => selected.indexOf(kode) !== -1;

  return (
    <Fragment>
      <div className="table-wrap-hasil-terbaru">
        <div className="head-search">
          <div className="btn-daftar-rujukan">
            <div className="search-style-rujuk">
              <input type="text" placeholder="Search By" onChange={e => setkeyword(e.target.value)} />
              <button className="search-rujukan" onClick={changeKeyword}>
                <BiSearch />
              </button>
            </div>
          </div>
        </div>

        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <HeaderTable
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={datas.length}
              activePaginate={activePaginate}
              //count match by selected array
              countMatch={datas.filter(data => selected.includes(data.kode)).length}
            />

            <TableBody>
              {stableSort(datas, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.kode);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow className={'checkTab'} hover role="checkbox" tabIndex={-1} key={row.kode}>
                      <TableCell padding="checkbox" onClick={event => handleClick(event, row.kode)}>
                        <Checkbox
                          className="checkTab"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                        {row.kode}
                      </TableCell>
                      <TableCell align="left">{row.nama}</TableCell>
                      <TableCell align="left">{row.alamat}</TableCell>
                      <TableCell align="left">{row.kota}</TableCell>
                      <TableCell align="left">{row.telp}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={pagination.total}
          rowsPerPage={rowsPerPage}
          page={activePaginate - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Fragment>
  );
};

export default DataHasilPasien;
