import React, { Fragment } from 'react';
import { BtnCancel } from './style';

const Buttonedit = ({ title, ...props }) => {
  return (
    <Fragment>
      <BtnCancel {...props}>{title}</BtnCancel>
    </Fragment>
  );
};

export default Buttonedit;
