import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${props => (props.width ? props.width : '10')}%;
  p {
    padding-right: 12px;
    min-width: 150px;
    text-align: ${props => (props.alignTitle ? props.alignTitle : 'right')};
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    margin-bottom: 16px;
    p {
      padding-right: 12px;
      min-width: 150px;
      text-align: left;
      margin-bottom: 6px;
    }
  }
`;

const Err = styled.p`
  font-size: 14px;
  color: #dc3545;
  padding-top: 4px;
  text-align: left !important;
`;

const Input = styled.input`
  background-color: #f0f4f9;
  height: 36px;
  display: flex;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0 12px;
  width: ${props => (props.width ? props.width : '10')}%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputForm = ({ title = 'name', width, alignTitle, error, ...props }) => {
  return (
    <Fragment>
      <Wrap width={width} alignTitle={alignTitle}>
        <p>{title}</p>
        <div style={{ width: '100%' }}>
          <Input width={width} type="text" {...props} />
          {error ? <Err>{error}</Err> : null}
        </div>
      </Wrap>
    </Fragment>
  );
};

export default InputForm;
