import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import User from './Pages/User';
import Price from './Pages/Price';
import Information from './Pages/Information';
import Cabang from './Pages/Cabang';
import Hasil from './Pages/Hasil';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Addcabang from './Pages/Cabang/Addcabang';
import Editcabang from './Pages/Cabang/Editcabang';
import Addinformation from './Pages/Information/Addinformation';
import Editinformation from './Pages/Information/Editinformation';
import Addupload from './Pages/Upload';
import Editupload from './Pages/Upload/Editupload';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/admin/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/admin/signin'} />;
  } else if (authUser && location.pathname === '/admin/signin') {
    return <Redirect to={'/informasi'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/admin/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path="/informasi" component={Information} />
        <RestrictedRoute path="/harga" component={Price} />
        <RestrictedRoute path="/user" component={User} />
        <RestrictedRoute path="/cabang" component={Cabang} />
        <RestrictedRoute path="/Hasil" component={Hasil} />
        <RestrictedRoute path="/tambah-cabang" component={Addcabang} />
        <RestrictedRoute path="/edit-cabang/:id" component={Editcabang} />
        <RestrictedRoute path="/add-information" component={Addinformation} />
        <RestrictedRoute path="/edit-information/:id" component={Editinformation} />
        <RestrictedRoute path="/add-upload" component={Addupload} />
        <RestrictedRoute path="/edit-upload" component={Editupload} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
