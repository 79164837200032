import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { getHasilList, setHasilPagination } from '../../redux/actions';

const HasilLoad = {
  getList: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword } = getState().hasil;
      console.log('keyword', keyword);
      dispatch(fetchStart());
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.hasil.list}?keyword=${keyword}&page=${activePaginate}`;
      } else {
        endpoin = `${url.hasil.list}?page=${activePaginate}`;
      }

      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            const result = data.admin ? data.admin.data : data.cabang.data;
            dispatch(getHasilList(result));
            dispatch(
              setHasilPagination({
                current_page: data.admin ? data.admin.current_page : data.cabang.current_page,
                per_page: data.admin ? data.admin.per_page : data.cabang.per_page,
                total: data.admin ? data.admin.total : data.cabang.total,
                last_page: data.admin ? data.admin.last_page : data.cabang.last_page,
                from: data.admin ? data.admin.from : data.cabang.from,
              }),
            );
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  delete: id => {
    return dispatch => {
      dispatch(fetchStart());
      let endpoin;
      if (id.filename !== '') {
        endpoin = `${url.hasil.delete}?nolab=${id.kode}&file=${id.filename}`;
      } else {
        endpoin = `${url.hasil.delete}?nolab=${id.kode}`;
      }
      return axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            fetchError(data.message);
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
};

export default HasilLoad;
