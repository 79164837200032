import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSignIn: {
    height: '53px',
    backgroundColor: '#F24141',
    borderRadius: '4px',
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 24,
    color: theme.palette.text.primary,
    fontWeight: '500',
    fontSize: '36px',
  },
  textFieldRoot: {
    backgroundColor: '#F0F4F9',
    borderRadius: '4px',
    height: '53px',
    border: 'none',
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& label.Mui-focused': {
      display: 'none',
    },
    '& legend': {
      display: 'none',
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    dispatch(AuhMethods[method].onLogin({ email, password }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7} mt={10}>
          <Box className={classes.authLogo}>
            <CmtImage src={'/images/biosys-logo.png'} width={135} height={65} />
          </Box>
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Sign in
        </Typography>
        <form>
          <Box mb={2}>
            <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#131313' }}>Email Address</p>
            <TextField
              fullWidth
              hiddenLabel
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="filled"
              className={classes.textFieldRoot}
              placeholder="Enter Email Address"
            />
          </Box>
          <Box mb={7}>
            <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#131313' }}>Password</p>
            <TextField
              type="password"
              fullWidth
              hiddenLabel
              InputLabelProps={{ shrink: false }}
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="filled"
              className={classes.textFieldRoot}
              placeholder="Enter Password"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary" fullWidth className={classes.buttonSignIn}>
              <IntlMessages id="appModule.signIn" />
            </Button>
          </Box>
        </form>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
