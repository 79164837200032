import {
  LOAD_DATA_PRICE,
  SET_PRICE_DATA,
  TAB_ACTIVE,
  SET_PRICE_PAGINATION,
  SET_ACTIVE_PRICE_PAGINATION,
  SET_KEYWORD_PRICE,
  SET_PRICE_LIMIT,
  SET_SEARCH_MODE,
} from '../../@jumbo/constants/ActionTypes';

export const loadDataPrice = loading => {
  return dispatch => {
    dispatch({
      type: LOAD_DATA_PRICE,
      payload: loading,
    });
  };
};

export const setDataPrice = data => {
  return dispatch => {
    dispatch({
      type: SET_PRICE_DATA,
      payload: data,
    });
  };
};

export const setTabActive = tab => {
  return dispatch => {
    dispatch({
      type: TAB_ACTIVE,
      payload: tab,
    });
  };
};

export const setPricePagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_PRICE_PAGINATION,
      payload: pagination,
    });
  };
};

export const setActivePricePagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_PRICE_PAGINATION,
      payload: pagination,
    });
  };
};

export const setKeywordPrice = keyword => {
  return dispatch => {
    dispatch({
      type: SET_KEYWORD_PRICE,
      payload: keyword,
    });
  };
};

export const setPriceLimit = limit => {
  return dispatch => {
    dispatch({
      type: SET_PRICE_LIMIT,
      payload: limit,
    });
  };
};

export const setSearchMode = mode => {
  return dispatch => {
    dispatch({
      type: SET_SEARCH_MODE,
      payload: mode,
    });
  };
};
