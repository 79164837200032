import React, { Fragment } from 'react';
import Addupload from './Addupload';
import './upload.css';

const index = () => {
  return (
    <Fragment>
      <Addupload />
    </Fragment>
  );
};

export default index;
