import styled from 'styled-components';
import React from 'react';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;

const WrapForm = ({ children }) => {
  return <Wrap>{children}</Wrap>;
};

export default WrapForm;
