import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { getCabangList, setCabangPagination } from '../../redux/actions';

const CabangLoad = {
  getList: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword } = getState().cabang;
      dispatch(fetchStart());
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.cabang.list}?keyword=${keyword}`;
      } else {
        endpoin = `${url.cabang.list}?page=${activePaginate}`;
      }

      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            const result = data.admin ? data.admin.data : data.cabang.data;
            dispatch(getCabangList(result));
            dispatch(
              setCabangPagination({
                current_page: data.admin ? data.admin.current_page : data.cabang.current_page,
                per_page: data.admin ? data.admin.per_page : data.cabang.per_page,
                total: data.admin ? data.admin.total : data.cabang.total,
                last_page: data.admin ? data.admin.last_page : data.cabang.last_page,
                from: data.admin ? data.admin.from : data.cabang.from,
              }),
            );
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  add: data => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(`${url.cabang.add}`, data)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
  delete: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(`${url.cabang.delete}`, id)
        .then(({ data, status }) => {
          if (status === 201) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            fetchError(data.message);
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
  GetDetail: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .get(`${url.cabang.detail}/${id}`)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return {
              status: true,
              message: data.message,
              data: data.cabangdetail,
            };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message, data: [] };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message, data: [] };
        });
    };
  },
  UpdateDetail: params => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .put(`${url.cabang.update}`, params)
        .then(({ data, status }) => {
          if ((status === 200 || status === 201) && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message, data: data };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message, data: [] };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message, data: [] };
        });
    };
  },
};

export default CabangLoad;
