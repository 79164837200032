import React, { Fragment } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { BtnAction } from './style';

const ButtonDelete = ({ ...props }) => {
  return (
    <Fragment>
      <BtnAction {...props}>
        <DeleteIcon fontSize="small" />
      </BtnAction>
    </Fragment>
  );
};

export default ButtonDelete;
