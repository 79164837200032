import React, { Fragment, useRef, useState } from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Container, Grid, Divider, Title, TitleMedium, WrapForm, InputForm, WrapButton, ButtonSubmit } from 'component';
import {
  AccountCircle,
  Close,
  DateRange,
  Mail,
  PinDrop,
  Cloud,
  Note,
  RemoveCircle,
  Dock,
  Apartment,
  LocalHospital,
  Phone,
} from '@material-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPhoneSquareAlt } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { AsyncPaginate } from 'react-select-async-paginate';
import Sampleoption from './loadOption';
import service from '../../../services/upload';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Upload', link: '/upload' },
  { label: 'New Upload', isActive: true },
];

const Addupload = () => {
  const valueInputFile = useRef();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const [pasien, SetPasien] = useState('');
  const [instansi, SetIdInstansi] = useState('');
  const [dokter, SetIdDokter] = useState('');

  const [value, setValue] = useState('');
  const [valueInstansi, setValueInstansi] = useState('');
  const [valueDokter, setValueDokter] = useState('');

  const [nolab, setNolab] = useState('');
  const [keterangan, setKeterangan] = useState('');

  const [files, setFiles] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  const [errorForm, setErrorForm] = useState({
    id: '',
    id_dokter: '',
    id_pasien: '',
    id_perusahaan: '',
    keterangan: '',
    tanggal_periksa: '',
  });

  const handleChange = value => {
    console.log(value);
    setValue(value);
    SetPasien(value.data);
  };

  const handleChangeInstansi = value => {
    console.log(value);
    setValueInstansi(value);
    SetIdInstansi(value.data);
  };

  const handleChangeDokter = value => {
    setValueDokter(value);
    console.log(value);
    SetIdDokter(value.data);
  };

  const handleSubmit = async () => {
    // console.log(value, valueInstansi, valueDokter, nolab, keterangan, startDate, files);
    let formData = new FormData();
    formData.append('id', nolab);
    formData.append('id_perusahaan', valueInstansi.value);
    formData.append('id_dokter', valueDokter.value);
    formData.append('id_pasien', value.value);
    formData.append('tanggal_periksa', moment(startDate).format('Y-MM-DD'));
    formData.append('keterangan', keterangan);
    formData.append('filenames', files[0]);
    const result = await dispatch(service.SubmitData(formData));
    // console.log(result);

    if (result.status === false) {
      const msg = result.message;
      const errorForm = {
        id: msg.id ? msg.id : '',
        id_dokter: msg.id_dokter ? msg.id_dokter : '',
        id_pasien: msg.id_pasien ? msg.id_pasien : '',
        id_perusahaan: msg.id_perusahaan ? msg.id_perusahaan : '',
        keterangan: msg.keterangan ? msg.keterangan : '',
        tanggal_periksa: msg.tanggal_periksa ? msg.tanggal_periksa : '',
      };
      setErrorForm(errorForm);
      // console.log(errorForm, 'errorForm.id_pasien');
    } else {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        SetPasien('');
        SetIdInstansi('');
        SetIdDokter('');
        setValue('');
        setValueDokter('');
        setValueInstansi('');
        setNolab('');
        setKeterangan('');
        setStartDate(new Date());
        setFiles([]);
        return true;
      });
    }
  };

  const handleUpload = e => {
    const newFiles = e.target.files[0];
    if (e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onloadend = d => {
        setFiles([newFiles]);
      };
      reader.readAsDataURL(newFiles);
    }
    valueInputFile.current.value = '';
  };

  const onRemoveFile = index => {
    let newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
    valueInputFile.current.value = '';
  };

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    let filteredOptions;
    let responseJSON;

    if (searchQuery === '') {
      const response = await service.getListpasien(page);
      responseJSON = await response.json();
      filteredOptions = responseJSON.personal.data.map(item => ({
        data: item,
        value: item.kode,
        label: `${item.kode}-${item.nama}`,
      }));
      const { last_page, current_page } = responseJSON.personal;
      return {
        options: filteredOptions,
        hasMore: current_page === last_page ? false : true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } else {
      const response = await service.getListpasienById(searchQuery, page);
      responseJSON = await response.json();
      filteredOptions = responseJSON.personal.data.map(item => ({
        data: item,
        value: item.kode,
        label: `${item.kode} - ${item.nama}`,
      }));
      const { last_page, current_page } = responseJSON.personal;

      return {
        options: filteredOptions,
        hasMore: current_page === last_page ? false : true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    }
  };

  const loadOptionsDokter = async (searchQuery, loadedOptions, { page }) => {
    let filteredOptions;
    let responseJSON;

    if (searchQuery === '') {
      const response = await service.getListDokter(page);
      responseJSON = await response.json();
      filteredOptions = responseJSON.doktor.data.map(item => ({
        data: item,
        value: item.kode,
        label: `${item.kode}-${item.nama}`,
      }));
      const { last_page, current_page } = responseJSON.doktor;
      return {
        options: filteredOptions,
        hasMore: current_page === last_page ? false : true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } else {
      const response = await service.getListDokterById(searchQuery, page);
      responseJSON = await response.json();
      filteredOptions = responseJSON.doktor.data.map(item => ({
        data: item,
        value: item.kode,
        label: `${item.kode}-${item.nama}`,
      }));
      const { last_page, current_page } = responseJSON.doktor;

      return {
        options: filteredOptions,
        hasMore: current_page === last_page ? false : true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    }
  };

  const loadOptionsInstansi = async (searchQuery, loadedOptions, { page }) => {
    let filteredOptions;
    let responseJSON;

    if (searchQuery === '') {
      const response = await service.getListInstansi(page);
      responseJSON = await response.json();
      filteredOptions = responseJSON.instansi.data.map(item => ({
        data: item,
        value: item.kode,
        label: `${item.kode}-${item.nama}`,
      }));
      const { last_page, current_page } = responseJSON.instansi;
      return {
        options: filteredOptions,
        hasMore: current_page === last_page ? false : true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } else {
      const response = await service.getListInstansiById(searchQuery, page);
      responseJSON = await response.json();
      filteredOptions = responseJSON.instansi.data.map(item => ({
        data: item,
        value: item.kode,
        label: `${item.kode}-${item.nama}`,
      }));
      const { last_page, current_page } = responseJSON.instansi;

      return {
        options: filteredOptions,
        hasMore: current_page === last_page ? false : true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    }
  };

  return (
    <Fragment>
      <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
        <Container>
          <h3>Upload new</h3>
          <Divider />
          <Title title={'Informasi Pasien'}>
            <AccountCircle fontSize="small" />
          </Title>
          <Grid />
          <div className="wrap-input">
            <h4>Id Pasien</h4>
            <div className="wrap-select">
              {/* <Select placeholder="Cari Id Pasien" options={options} value={value} onChange={handleChange} /> */}
              <AsyncPaginate
                value={value}
                loadOptions={loadOptions}
                onChange={handleChange}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                placeholder="cari id pasien"
                additional={{
                  page: 1,
                }}
              />
              {errorForm.id_pasien && <span style={{ color: 'red', fontSize: '14px' }}>{errorForm.id_pasien}</span>}
            </div>
          </div>
          {pasien && (
            <div className="wrap-selected">
              <div className="close-selected">
                <Close
                  onClick={() => {
                    SetPasien('');
                    setValue(null);
                  }}
                  fontSize="small"
                  style={{ color: 'red' }}
                />
              </div>

              <section className="show-selected">
                <div style={{ minWidth: '50%' }}>
                  <TitleMedium title={'Id Pasien'} />
                  <p>{pasien.kode}</p>
                  <Grid />
                  <TitleMedium title={'Nama Pasien'}>
                    <AccountCircle fontSize="small" />
                  </TitleMedium>
                  <p>{pasien.nama}</p>
                  <Grid />
                </div>
                <div>
                  <TitleMedium title={'Telepon'}>
                    <DateRange fontSize="small" />
                  </TitleMedium>
                  <p>{pasien.telp}</p>
                  <Grid />
                  <TitleMedium title={'Alamat'}>
                    <PinDrop fontSize="small" />
                  </TitleMedium>
                  <p>{pasien.alamat}</p>
                </div>
              </section>
            </div>
          )}
          <Divider />

          {/* INFORMASI INSTANSI */}
          <Title title={'ID Instansi'}>
            <Apartment fontSize="small" />
          </Title>
          <Grid />
          <div className="wrap-input">
            <h4>Id Instansi</h4>
            <div className="wrap-select">
              {/* <Select
                placeholder="Cari Id Instansi"
                options={options}
                value={valueInstansi}
                onChange={handleChangeInstansi}
              /> */}
              <AsyncPaginate
                value={valueInstansi}
                loadOptions={loadOptionsInstansi}
                onChange={handleChangeInstansi}
                placeholder="cari id Instansi"
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                additional={{
                  page: 1,
                }}
              />
              {errorForm.id_perusahaan && <span style={{ color: 'red', fontSize: '14px' }}>{errorForm.id_perusahaan}</span>}
              {/* <span style={{ color: 'red', fontSize: '10px' }}>{errorForm.id_perusahaan}</span> */}
            </div>
          </div>
          {instansi && (
            <div className="wrap-selected">
              <div className="close-selected">
                <Close
                  onClick={() => {
                    SetIdInstansi('');
                    setValueInstansi(null);
                  }}
                  fontSize="small"
                  style={{ color: 'red' }}
                />
              </div>

              <section className="show-selected">
                <div style={{ minWidth: '50%' }}>
                  <TitleMedium title={'Nama Instansi'} />
                  <p>{instansi.nama}</p>
                  <Grid />
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '80%',
                      }}>
                      <div>
                        <TitleMedium title={'Kota'}>
                          <PinDrop fontSize="small" />
                        </TitleMedium>
                        <p>{instansi.kota ? instansi.kota : '-'}</p>
                        <Grid />
                      </div>
                      <div>
                        <TitleMedium title={'No Telepon'}>
                          <Phone fontSize="small" />
                        </TitleMedium>
                        <p>{instansi.telp}</p>
                      </div>
                    </div>
                    <div style={{ width: '100%', minWidth: '100%' }}>
                      <TitleMedium title={'Alamat'}>
                        <PinDrop fontSize="small" />
                      </TitleMedium>
                      <p>{instansi.alamat ? instansi.alamat : '-'}</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          <Divider />
          {/* INFORMASI INSTANSI END */}

          {/* INFORMASI DOKTER */}
          <Title title={'ID Dokter'}>
            <LocalHospital fontSize="small" />
          </Title>
          <Grid />
          {/* <div className="wrap-input">
            <h4>Id Dokter</h4>
            <div className="wrap-select">
              <Select placeholder="Cari Id Pasien" options={options} value={value} onChange={handleChange} />
            </div>
          </div> */}

          <div className="wrap-input">
            <h4>Id Dokter</h4>
            <div className="wrap-select">
              {/* <Select
                placeholder="Cari Id Instansi"
                options={options}
                value={valueInstansi}
                onChange={handleChangeInstansi}
              /> */}
              <AsyncPaginate
                value={valueDokter}
                loadOptions={loadOptionsDokter}
                onChange={handleChangeDokter}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                placeholder="cari id dokter"
                additional={{
                  page: 1,
                }}
              />
              {errorForm.id_dokter && <span style={{ color: 'red', fontSize: '14px' }}>{errorForm.id_dokter}</span>}
              {/* <span style={{ color: 'red', fontSize: '10px' }}>{errorForm.id_dokter}</span> */}
            </div>
          </div>
          {dokter && (
            <div className="wrap-selected">
              <div className="close-selected">
                <Close
                  onClick={() => {
                    SetIdDokter('');
                    setValueDokter(null);
                  }}
                  fontSize="small"
                  style={{ color: 'red' }}
                />
              </div>

              <section className="show-selected">
                <div style={{ minWidth: '50%' }}>
                  <TitleMedium title={'Kode Dokter'} />
                  <p>{dokter.kode}</p>
                  <Grid />
                  <TitleMedium title={'Nama Dokter'}>
                    <AccountCircle fontSize="small" />
                  </TitleMedium>
                  <p>{dokter.nama}</p>
                  <Grid />
                </div>
                <div>
                  <TitleMedium title={'Nomor Telepon'}>
                    <FaPhoneSquareAlt />
                  </TitleMedium>
                  <p>12 Juni 1996</p>
                  <Grid />
                  <TitleMedium title={'Alamat'}>
                    <MdLocationOn />
                  </TitleMedium>
                  <p>{dokter.alamat}</p>
                </div>
              </section>
            </div>
          )}
          <Divider />
          {/* INFORMASI DOKTER END */}

          {/* FILE */}
          <Title title={'File'}>
            <Dock fontSize="small" />
          </Title>
          <Grid />

          <WrapForm>
            <InputForm
              title="Nomor Lab"
              placeholder="Nomor Lab"
              width="40"
              onChange={e => setNolab(e.target.value)}
              value={nolab}
              error={errorForm.id_perusahaan}
            />
            {/* <span style={{ color: 'red', fontSize: '10px' }}>{errorForm.id_perusahaan}</span> */}
            <div className="tgl-periksa">
              <p>Tanggal Periksa</p>
              <DatePicker selected={startDate} dateFormat="Y-M-d" onChange={date => setStartDate(date)} />
              <span style={{ color: 'red', fontSize: '14px' }}>{errorForm.tanggal_periksa}</span>
            </div>
          </WrapForm>
          <Grid />
          <WrapForm>
            <InputForm
              title="Keterangan File"
              placeholder="Nama File"
              width="50"
              onChange={e => setKeterangan(e.target.value)}
              value={keterangan}
              error={errorForm.keterangan}
            />
            {/* <span style={{ color: 'red', fontSize: '10px' }}>{errorForm.keterangan}</span> */}
          </WrapForm>

          <Grid />
          <div className="wrap-uploadfile-upload">
            <p>Upload Files</p>
            <div className="file file--upload">
              <label htmlFor="input-file">
                <Cloud style={{ marginRight: 6 }} fontSize="small" /> Upload File Pdf
              </label>

              <input
                id="input-file"
                type="file"
                // multiple
                onChange={handleUpload}
                ref={valueInputFile}
                accept="application/pdf"
              />
              <span style={{ color: 'red', fontSize: '14px' }}>{errorForm.filenames}</span>
            </div>
          </div>

          <div>
            {files.map((data_file, index) => {
              return (
                <div key={index} className="list-upload">
                  <div style={{ marginRight: 6 }}>
                    <Note fontSize="small" />
                  </div>
                  <p>{files[index].name}</p>
                  <div style={{ cursor: 'pointer' }} onClick={() => onRemoveFile(index)}>
                    <RemoveCircle fontSize="small" />
                  </div>
                </div>
              );
            })}
          </div>
          <Divider />
          {/* FILE END */}

          <WrapButton>
            <ButtonSubmit title={'Simpan'} onClick={handleSubmit} />
          </WrapButton>
        </Container>
      </PageContainer>
    </Fragment>
  );
};

export default Addupload;
