import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import React from 'react';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCabangPagination, setKeywordHasil } from '../../../redux/actions';
import hasilLoad from '../../../services/hasil';

// import { datas } from './sampleData';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Hasil', isActive: true },
];

const Hasil = () => {
  const dispatch = useDispatch();
  const { pagination, activePaginate, keyword, hasilData } = useSelector(state => state.hasil);
  const MySwal = withReactContent(Swal);
  React.useEffect(() => {
    dispatch(hasilLoad.getList());
  }, [dispatch, activePaginate, keyword]);

  const apiDeleteHandler = async (kode, filename = null) => {
    const result = await dispatch(hasilLoad.delete({ kode, filename }));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      });
      dispatch(hasilLoad.getList());
    }
  };

  const deleteAction = (id, filename = null) => {
    MySwal.fire({
      title: 'Do you want to Delete this file?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        apiDeleteHandler(id, filename);
      }
    });
  };

  return (
    <PageContainer heading="Hasil" breadcrumbs={breadcrumbs}>
      <Table datas={hasilData} deleteAction={deleteAction} />
    </PageContainer>
  );
};

export default Hasil;
