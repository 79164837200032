import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  padding-right: ${props => props.spacing || '0px'};
  width: 25%;
  @media (max-width: 768px) {
    /* flex-direction: column; */
    /* background-color: red; */
    width: 100%;
  }
`;

export const Input = styled.input`
  background-color: #f0f4f9;
  outline: none;
  border: none;
  height: 36px;
  padding-left: 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  width: 100%;
`;

export const Btn = styled.button`
  border: none;
  outline: none;
  padding: 0 10px;
  background-color: #e6e8ea;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
