const url = {
  baseUrl: 'https://api.klinikrapha.co.id/api',
  baseContent: 'https://api.klinikrapha.co.id',
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
    refresh: '/auth/refresh',
    me: '/auth/me',
  },
  price: {
    umum: '/admin/price-umum',
    khusus: '/admin/price-khusus',
    showHide: '/admin/price-show-hide',
    searchKhusus: '/admin/price-khusus/search',
    searchUmum: '/admin/price-umum/search',
    searchHidden: '/admin/price-show-hide/search',
    update: 'admin/price-show-hide/update',
    delete: 'admin/price-khusus/search/delete',
  },
  user: {
    list: '/admin/list-admin',
    add: '/admin/add',
    delete: '/admin/delete',
    detail: '/admin/detail',
    update: '/admin/update',
  },
  cabang: {
    list: '/admin/cabang/list',
    add: '/admin/cabang/add',
    detail: '/admin/cabang/detail',
    update: '/admin/cabang/update',
    delete: '/admin/cabang/delete',
  },
  hasil: {
    list: '/admin/hasil',
    delete: '/admin/hasil/delete',
  },
  informasi: {
    list: '/admin/informasi',
    list_search: '/admin/informasi/search',
    add: '/admin/informasi/addv2',
    instansi: '/admin/informasi/instansi',
    instansi_search: '/admin/informasi/instansi/search',
    doktor: '/admin/informasi/doktor',
    doktor_search: '/admin/informasi/doktor/search',
    personal: '/admin/informasi/personal',
    personal_search: '/admin/informasi/personal/search',
    update: '/admin/informasi/updatev2',
    delete: '/admin/informasi/delete',
    detail: '/admin/informasi/detailv2',
  },
  uploader: {
    instansi: '/admin/uploader/instansi/search',
    pasien: '/admin/uploader/pasien/search',
    dokter: '/admin/uploader/dokter/search',
    submit: '/admin/uploader/submit',
  },
};

export default url;
