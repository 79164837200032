import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Price from './Price';
import User from './User';
import Cabang from './Cabang';
import Hasil from './Hasil';
import Informasi from './Infromasi';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    price: Price,
    user: User,
    cabang: Cabang,
    hasil: Hasil,
    inform: Informasi,
  });
