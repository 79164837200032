import styled from 'styled-components';
import React from 'react';

const Wrap = styled.div`
  overflow: auto;
`;

const Wraptable = ({ children }) => {
  return <Wrap>{children}</Wrap>;
};

export default Wraptable;
