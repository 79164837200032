import { Container, InputForm, TextArea, WrapForm, Grid, Divider, ButtonSubmit, ButtonCancel, WrapButton } from 'component';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cabangLoad from '../../../services/cabang';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Cabang', link: '/cabang' },
  { label: 'Edit Data Cabang', isActive: true },
];

const useStyles = makeStyles({
  errorForm: {
    display: 'block',
    fontSize: '10px',
    color: 'red',
  },
});

const Editcabang = () => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const MySwal = withReactContent(Swal);

  const { id } = useParams();

  const [form, setForm] = useState({
    kode: '',
    nama: '',
    alamat: '',
    kota: '',
    telp: '',
    ipaddress: '',
    userdb: '',
    passdb: '',
    namadb: '',
  });

  const [errorForm, setErrorForm] = useState({
    kode: null,
    nama: null,
    alamat: null,
    kota: null,
    telp: null,
    ipaddress: null,
    userdb: null,
    passdb: null,
    namadb: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const rest = await dispatch(cabangLoad.GetDetail(id));
      if (rest.status === true) {
        setForm({
          kode: rest.data.kode,
          nama: rest.data.nama,
          alamat: rest.data.alamat,
          kota: rest.data.kota,
          telp: rest.data.telp,
          ipaddress: rest.data.ipaddress,
          userdb: rest.data.userdb,
          passdb: rest.data.passdb,
          namadb: rest.data.namadb,
        });
      }
    };

    fetchData();
  }, [dispatch, id]);

  const redirectTo = () => {
    navigate.push('/cabang');
  };

  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const updateData = async () => {
    let request;
    if (form.passdb !== '') {
      request = {
        kode: form.kode,
        nama: form.nama,
        alamat: form.alamat,
        kota: form.kota,
        telp: form.telp,
        ipaddress: form.ipaddress,
        userdb: form.userdb,
        namadb: form.namadb,
      };
    } else {
      request = {
        kode: form.kode,
        nama: form.nama,
        alamat: form.alamat,
        kota: form.kota,
        telp: form.telp,
        ipaddress: form.ipaddress,
        userdb: form.userdb,
        passdb: form.passdb,
        namadb: form.namadb,
      };
    }
    const rest = await dispatch(cabangLoad.UpdateDetail(form));
    if (rest.status === true) {
      await MySwal.fire({
        title: <p>{rest.message}</p>,
        icon: 'success',
      });
      navigate.push('/cabang');
    } else {
      const msg = rest.message;
      setErrorForm({
        kode: msg?.kode ? msg.kode[0] : null,
        nama: msg?.nama ? msg.nama[0] : null,
        alamat: msg?.alamat ? msg.alamat[0] : null,
        kota: msg?.kota ? msg.kota[0] : null,
        telp: msg?.telp ? msg.telp[0] : null,
        ipaddress: msg?.ipaddress ? msg.ipaddress[0] : null,
        userdb: msg?.userdb ? msg.userdb[0] : null,
        passdb: msg?.passdb ? msg.passdb[0] : null,
        namadb: msg?.namadb ? msg.namadb[0] : null,
      });
    }
  };
  return (
    <Fragment>
      <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
        <Container>
          <h3>Edit Data Cabang</h3>
          <Divider />
          <h3>Informasi Cabang</h3>
          <Grid />
          <WrapForm>
            <InputForm
              title="Kode Cabang"
              placeholder="Kode Cabang"
              width="50"
              onChange={handleChange}
              name="kode"
              value={form.kode}
            />
            {errorForm.kode && <span className={classes.errorForm}>{errorForm.kode}</span>}
            <InputForm
              title="Nama Cabang"
              placeholder="Nama Cabang"
              width="100"
              onChange={handleChange}
              name="nama"
              value={form.nama}
            />
            {errorForm.nama && <span className={classes.errorForm}>{errorForm.nama}</span>}
          </WrapForm>
          <Grid />
          <WrapForm>
            <TextArea
              title="Alamat"
              placeholder="Alamat"
              width="100"
              onChange={handleChange}
              name="alamat"
              value={form.alamat}
            />
            {errorForm.alamat && <span className={classes.errorForm}>{errorForm.alamat}</span>}
          </WrapForm>
          <Grid />
          <WrapForm>
            <InputForm title="Kota" placeholder="Kota" width="100" onChange={handleChange} name="kota" value={form.kota} />
            {errorForm.kota && <span className={classes.errorForm}>{errorForm.kota}</span>}
            <InputForm
              title="Telepon"
              placeholder="Telepon"
              width="100"
              onChange={handleChange}
              name="telp"
              value={form.telp}
            />
            {errorForm.telp && <span className={classes.errorForm}>{errorForm.telp}</span>}
          </WrapForm>
          <Grid />
          <Divider />
          <h3>Informasi Database</h3>
          <Grid />
          <WrapForm>
            <InputForm
              title="Nama Database"
              placeholder="Nama Database"
              width="100"
              onChange={handleChange}
              name="namadb"
              value={form.namadb}
            />
            {errorForm.namadb && <span className={classes.errorForm}>{errorForm.namadb}</span>}
            <InputForm
              title="User Database"
              placeholder="User Database"
              width="100"
              onChange={handleChange}
              name="userdb"
              value={form.userdb}
            />
            {errorForm.userdb && <span className={classes.errorForm}>{errorForm.userdb}</span>}
          </WrapForm>
          <Grid />
          <WrapForm>
            <InputForm
              title="Password Database"
              placeholder="Password Database"
              width="100"
              onChange={handleChange}
              name="passdb"
              type="password"
            />
            {errorForm.passdb && <span className={classes.errorForm}>{errorForm.passdb}</span>}
            <InputForm
              title="Ip Database"
              placeholder="Ip Database"
              width="100"
              onChange={handleChange}
              name="ipaddress"
              value={form.ipaddress}
            />
            {errorForm.ipaddress && <span className={classes.errorForm}>{errorForm.ipaddress}</span>}
          </WrapForm>

          <Grid />
          <Grid />
          <WrapButton>
            <ButtonCancel onClick={redirectTo} title={'Cancel'} />
            <ButtonSubmit title={'Simpan'} onClick={updateData} />
          </WrapButton>
        </Container>
      </PageContainer>
    </Fragment>
  );
};

export default Editcabang;
