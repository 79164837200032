import React, { Fragment, useState } from 'react';
import { Styles, useStyles, PaginStyle } from './style';
import { Container } from './../../../component';
import { useTable, usePagination } from 'react-table';
import Paginations from '@material-ui/lab/Pagination';
import { ButtonDelete, ButtonEdit, WrapTable } from 'component';
import { Input, Button, Grid } from './../../../component';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setKeywordCabang } from '../../../redux/actions';

const Table = ({ columns, data }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();

  const redirectTo = () => {
    navigate.push('/tambah-cabang');
  };

  const classes = useStyles();
  const [keyword, setkeyword] = useState('');
  const { pagination } = useSelector(state => state.cabang);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, gotoPage, pageCount } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  const changeKeyword = () => {
    console.log(keyword, 'keyword');
    dispatch(setKeywordCabang(keyword));
  };

  return (
    <Fragment>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            spacing={'12px'}
            placeholder="Search..."
            onClick={changeKeyword}
            onChange={e => setkeyword(e.target.value)}
          />
          <Button title={'New Cabang'} onClick={redirectTo}>
            <AddIcon fontSize="small" />
          </Button>
        </div>

        <Grid />
        <WrapTable>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ height: '100%' }}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </WrapTable>
      </Container>
      <Grid />
      <PaginStyle>
        <Paginations
          className={classes.ul}
          count={pagination.last_page}
          variant="outlined"
          shape="rounded"
          onChange={(d, i) => {
            gotoPage(i - 1);
          }}
        />
      </PaginStyle>
    </Fragment>
  );
};

function App({ datas, deleteAction }) {
  const navigate = useHistory();

  const redirectToEdit = kodeId => {
    navigate.push(`/edit-cabang/${kodeId}`);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Kode Cabang',
        accessor: 'kode',
      },
      {
        Header: 'Nama Cabang',
        accessor: 'nama',
      },
      {
        Header: 'Ip Address',
        accessor: 'ipaddress',
      },
      {
        Header: 'Database Name',
        accessor: 'namadb',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: d => (
          <div style={{ display: 'flex' }}>
            <ButtonEdit onClick={() => redirectToEdit(d.row.original.kode)} />
            <ButtonDelete onClick={() => deleteAction(d.row.original.kode)} />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Styles>
      <Table columns={columns} data={datas} />
    </Styles>
  );
}

export default App;
