import {
  SET_ADMIN_DATA,
  SET_ACTIVE_ADMIN_PAGINATION,
  SET_ADMIN_PAGINATION,
  SET_KEYWORD,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  adminData: [],
  activePaginate: 1,
  pagination: {
    current_page: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
    from: 0,
  },
  keyword: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ADMIN_DATA: {
      return {
        ...state,
        adminData: action.payload,
      };
    }
    case SET_ADMIN_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case SET_ACTIVE_ADMIN_PAGINATION: {
      return {
        ...state,
        activePaginate: action.payload,
      };
    }
    case SET_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    default:
      return state;
  }
};
