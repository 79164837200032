import React, { Fragment } from 'react';
import EditIcon from '@material-ui/icons/EditTwoTone';
import { BtnSubmit } from './style';

const Buttonedit = ({ title, ...props }) => {
  return (
    <Fragment>
      <BtnSubmit {...props}>
        {/* <EditIcon fontSize="small" /> */}
        {title}
      </BtnSubmit>
    </Fragment>
  );
};

export default Buttonedit;
