import {
  SET_HASIL_DATA,
  SET_ACTIVE_HASIL_PAGINATION,
  SET_HASIL_PAGINATION,
  SET_HASIL_KEYWORD,
  LOAD_DATA_CABANG,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  hasilData: [],
  activePaginate: 1,
  pagination: {
    current_page: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
    from: 0,
  },
  keyword: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_HASIL_DATA: {
      return {
        ...state,
        hasilData: action.payload,
      };
    }
    case SET_HASIL_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case SET_ACTIVE_HASIL_PAGINATION: {
      return {
        ...state,
        activePaginate: action.payload,
      };
    }
    case SET_HASIL_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    default:
      return state;
  }
};
