import {
  SET_INFORMASI_DATA,
  SET_INFORMASI_KEYWORD,
  SET_INFORMASI_PAGINATION,
  SET_ACTIVE_INFORMASI_PAGINATION,
  SET_INFORM_DEFAULT_STATE,
  SET_INSTANSI_LIMIT,
} from '../../@jumbo/constants/ActionTypes';

export const getInfromasiList = data => {
  return dispatch => {
    dispatch({
      type: SET_INFORMASI_DATA,
      payload: data,
    });
  };
};

export const setDefaultStateInform = data => {
  return dispatch => {
    dispatch({
      type: SET_INFORM_DEFAULT_STATE,
      payload: data,
    });
  };
};

export const setInfrormasiPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_INFORMASI_PAGINATION,
      payload: pagination,
    });
  };
};

export const setActiveInfromastionPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_INFORMASI_PAGINATION,
      payload: pagination,
    });
  };
};

export const setKeywordInformasi = keyword => {
  return dispatch => {
    dispatch({
      type: SET_INFORMASI_KEYWORD,
      payload: keyword,
    });
  };
};

export const setInstansiLimit = keyword => {
  return dispatch => {
    dispatch({
      type: SET_INSTANSI_LIMIT,
      payload: keyword,
    });
  };
};
