import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Input = styled.input`
  background-color: #f0f4f9;
  outline: none;
  border: none;
  height: 36px;
  padding-left: 12px;
  border-radius: 4px;
  width: 100%;
`;

export const Styles = styled.div`
  table {
    border-spacing: 0;
    width: 100%;
    border-spacing: 0;

    thead {
      background-color: #f6f8fa;
      height: 46px;
      color: #7b7b7b;
      text-align: left;
    }

    tr {
      &:hover {
        background-color: #f6f8fa5e;
        cursor: pointer;
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      :last-child {
        border-right: 0;
      }
    }

    tbody {
    }
  }

  @media (max-width: 768px) {
    table {
      border-spacing: 0;
      width: 100%;
      border-spacing: 0;

      thead {
        background-color: #f6f8fa;
        height: 46px;
        color: #7b7b7b;
        text-align: left;
      }

      tr {
        &:hover {
          background-color: #f6f8fa5e;
          cursor: pointer;
        }
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        min-width: 165px;
        margin: 0;
        padding: 0.5rem;
        :last-child {
          border-right: 0;
        }
      }

      tbody {
      }
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: #f7f7fa;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
    outline: none;
    border-radius: 4px;
    margin-right: 12px;
    color: black;
  }
`;

export const PaginStyle = styled.div`
  /* position: absolute; */
  /* bottom: 24px; */
  /* right: 24px; */
  display: flex;
  justify-content: end;
`;

export const useStyles = makeStyles(theme => ({
  ul: {
    '& .MuiPaginationItem-page': {
      color: '#131313',
      background: '#F7F7FA',
      border: 'none',
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#F1595A',
    },
  },
}));
