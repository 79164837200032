import React, { Fragment } from 'react';
import { Btn } from './style';

const index = ({ title, children, ...props }) => {
  return (
    <Fragment>
      <Btn {...props}>
        {children}
        <span style={{ paddingLeft: 6 }} />
        {title}
      </Btn>
    </Fragment>
  );
};

export default index;
