import styled from 'styled-components';

import React from 'react';

const Grid = styled.div`
  width: 100%;
  height: ${props => props.spacing || '24px'};
`;

const index = () => {
  return <Grid />;
};

export default index;
