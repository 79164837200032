import styled from 'styled-components';
import React from 'react';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

const WrapButton = ({ children }) => {
  return <Wrap>{children}</Wrap>;
};

export default WrapButton;
