import React, { Fragment, useState } from 'react';
import { Styles, useStyles, PaginStyle } from './style';
import { Container } from './../../../component';
import Paginations from '@material-ui/lab/Pagination';
import { ButtonDelete, WrapTable } from 'component';
import { Input, Button, Grid } from './../../../component';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setKeywordHasil,
  setActiveCabangPagination,
  setHasilPagination,
  setActiveHasilPagination,
} from '../../../redux/actions';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import url from '@jumbo/constants/UrlConstant';

const Table = ({ columns, data }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [keyword, setkeyword] = useState('');
  const { pagination } = useSelector(state => state.hasil);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination,
  );

  const changeKeyword = () => {
    dispatch(setKeywordHasil(keyword));
  };

  const handleReset = () => {
    window.location.reload();
  };

  const handlePagination = (page = null) => {
    dispatch(setActiveHasilPagination(page));
  };

  return (
    <Fragment>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            spacing={'12px'}
            placeholder="Search..."
            onClick={changeKeyword}
            onChange={e => setkeyword(e.target.value)}
          />

          <Button spacing={'12px'} onClick={handleReset}>
            Reset
          </Button>
        </div>

        <Grid />
        <WrapTable>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ height: '100%' }}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </WrapTable>
      </Container>
      <Grid />
      <PaginStyle>
        <Paginations
          className={classes.ul}
          count={pagination.last_page}
          variant="outlined"
          shape="rounded"
          onChange={(d, i) => {
            gotoPage(i - 1);
            handlePagination(i);
          }}
        />
      </PaginStyle>
    </Fragment>
  );
};

function App({ datas, deleteAction }) {
  const navigate = useHistory();

  const redirectopenFile = filename => {
    window.open(url.baseContent + filename, '_blank', 'noreferrer');
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'No Reg',
        accessor: 'noreg',
      },
      {
        Header: 'No Lab',
        accessor: 'nolab',
      },
      {
        Header: 'Nama',
        accessor: 'nama',
      },
      {
        Header: 'Alamat',
        accessor: 'alamat',
      },
      {
        Header: 'Filename',
        accessor: 'filename',
      },
      {
        Header: 'ShowFile',
        accessor: 'file',
        Cell: d => (
          <div style={{ display: 'flex' }}>
            <VisibilityIcon onClick={() => redirectopenFile(d.row.original.file)} />
          </div>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: d => (
          <div style={{ display: 'flex' }}>
            <ButtonDelete onClick={() => deleteAction(d.row.original.nolab, d.row.original.file)} />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Styles>
      <Table columns={columns} data={datas} />
    </Styles>
  );
}

export default App;
