import styled from 'styled-components';

export const Btn = styled.button`
  background-color: #fde6e6;
  color: #f24141;
  outline: none;
  border-radius: 4px;
  padding: 0 24px;
  border: none;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

export const BtnAction = styled.button`
  background-color: #f6f8fa;
  outline: none;
  border: none;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-right: 6px;

  &:hover {
    background-color: #fde6e6;
    border-radius: 2px;
    color: #f24141;
    cursor: pointer;
    margin-right: 6px;
  }
`;

export const BtnSubmit = styled.button`
  background-color: #f24141;
  outline: none;
  border: none;
  height: 33px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 6px;
  color: white;

  &:hover {
    /* background-color: #fde6e6; */
    /* border-radius: 2px; */
    /* color: #f24141; */
    cursor: pointer;
    /* margin-right: 6px; */
  }
`;

export const BtnCancel = styled.button`
  /* background-color: #f24141; */
  outline: none;
  border: none;
  height: 33px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 6px;
  color: #666666;

  &:hover {
    /* background-color: #fde6e6; */
    /* border-radius: 2px; */
    /* color: #f24141; */
    cursor: pointer;
    /* margin-right: 6px; */
  }
`;
