import axios from 'axios';
import url from '@jumbo/constants/UrlConstant';

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken;
}
const instance = axios.create({
  baseURL: url.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  _retry: false,
});

instance.interceptors.request.use(
  config => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry && getLocalAccessToken() !== null) {
        originalConfig._retry = true;
        try {
          const rs = await refreshToken();
          const { accessToken } = rs.data;
          window.localStorage.setItem('accessToken', accessToken);
          instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          return instance(originalConfig);
        } catch (_error) {
          window.localStorage.clear();
          if (_error.response && _error.response.data) {
            window.localStorage.clear();
            return Promise.reject(_error.response.data);
          }
          window.localStorage.clear();
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  },
);

async function refreshToken() {
  instance
    .post(url.auth.refresh, {})
    .then(res => res)
    .catch(err => err);
}

export default instance;
