import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  width: 100%;
  padding: 24px 0;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dadada;
`;
const index = () => {
  return (
    <Fragment>
      <Wrap>
        <Line />
      </Wrap>
    </Fragment>
  );
};

export default index;
