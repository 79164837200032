import {
  SET_ADMIN_DATA,
  SET_ACTIVE_ADMIN_PAGINATION,
  SET_ADMIN_PAGINATION,
  SET_KEYWORD,
} from '../../@jumbo/constants/ActionTypes';

export const getAdminList = data => {
  return dispatch => {
    dispatch({
      type: SET_ADMIN_DATA,
      payload: data,
    });
  };
};

export const setActiveAdminPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_ADMIN_PAGINATION,
      payload: pagination,
    });
  };
};

export const setAdminPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_ADMIN_PAGINATION,
      payload: pagination,
    });
  };
};

export const setKeywordAdmin = keyword => {
  return dispatch => {
    dispatch({
      type: SET_KEYWORD,
      payload: keyword,
    });
  };
};
