import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from '@jumbo/utils/axiosHelper';
//use this Helper to access public API with no need authentication
import instance from './config';
import url from '../../../@jumbo/constants/UrlConstant';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      instance
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('accessToken', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        instance
          .post(url.auth.login, {
            email: email,
            password: password,
          })
          .then(({ data, status }) => {
            if (status === 201) {
              localStorage.setItem('accessToken', data.access_token);
              // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
              dispatch(fetchSuccess());
              /*active this line if getUserApi is ready
              
              */
              dispatch(JWTAuth.getAuthUser(true, data.access_token));
            } else {
              if (data.success === false) {
                let errorMessage = '';
                if (data.message?.email) {
                  errorMessage = data.message?.email[0];
                } else {
                  errorMessage = data.message?.password[0];
                }
                dispatch(fetchError(errorMessage));
              }
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response?.data?.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(url.auth.logout)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            localStorage.removeItem('accessToken');
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('accessToken');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get(url.auth.me)
        .then(({ data, status }) => {
          if (status === 201) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.user));
          } else {
            dispatch(setAuthUser(null));
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(setAuthUser(null));
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
