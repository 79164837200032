import React, { Fragment } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Input, Wrap, Btn } from './style';

const index = ({ onClick, spacing, ...input }) => {
  return (
    <Fragment>
      <Wrap spacing={spacing}>
        <Input {...input} />
        <Btn onClick={onClick}>
          <SearchIcon />
        </Btn>
      </Wrap>
    </Fragment>
  );
};

export default index;
