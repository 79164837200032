import {
  SET_INFORMASI_DATA,
  SET_INFORMASI_KEYWORD,
  SET_INFORMASI_PAGINATION,
  SET_ACTIVE_INFORMASI_PAGINATION,
  SET_INSTANSI_DATA,
  SET_INSTANSI_KEYWORD,
  SET_INSTANSI_PAGINATION,
  SET_ACTIVE_INSTANSI_PAGINATION,
  SET_PERSONAL_DATA,
  SET_PERSONAL_KEYWORD,
  SET_PERSONAL_PAGINATION,
  SET_ACTIVE_PERSONAL_PAGINATION,
  SET_DOKTOR_DATA,
  SET_DOKTOR_KEYWORD,
  SET_DOKTOR_PAGINATION,
  SET_ACTIVE_DOKTOR_PAGINATION,
  SET_INFORM_DEFAULT_STATE,
  SET_INSTANSI_LIMIT,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  InformasiData: [],
  InstansiData: [],
  PersonalData: [],
  DoktorData: [],
  activePaginate: 1,
  activeLimit: 10,
  activeInstantionPaginate: 1,
  activePersonalPaginate: 1,
  activeDoktorPaginate: 1,
  pagination: {
    current_page: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
    from: 0,
  },
  keyword: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INFORMASI_DATA: {
      return {
        ...state,
        // InformasiData: [...state.InformasiData, ...action.payload],
        InformasiData: action.payload,
      };
    }
    case SET_INFORMASI_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case SET_ACTIVE_INFORMASI_PAGINATION: {
      return {
        ...state,
        activePaginate: action.payload,
      };
    }
    case SET_INFORMASI_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    case SET_INFORM_DEFAULT_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    case SET_INSTANSI_LIMIT: {
      return {
        ...state,
        activeLimit: action.payload,
      };
    }
    default:
      return state;
  }
};
