import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';

const uploadApi = {
  getListpasienById: async (id, page) => {
    return await fetch(`${url.baseUrl}${url.uploader.pasien}?keyword=${id}&page=${page}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      }),
    });
  },
  getListDokterById: async (id, page) => {
    return await fetch(`${url.baseUrl}${url.uploader.dokter}?keyword=${id}&page=${page}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      }),
    });
  },
  getListInstansiById: async (id, page) => {
    return await fetch(`${url.baseUrl}${url.uploader.instansi}?keyword=${id}&page=${page}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      }),
    });
  },
  getListpasien: async page => {
    return await fetch(`${url.baseUrl}${url.informasi.personal}?page=${page}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      }),
    });
  },
  getListDokter: async page => {
    return await fetch(`${url.baseUrl}${url.informasi.doktor}?page=${page}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      }),
    });
  },
  getListInstansi: async page => {
    return await fetch(`${url.baseUrl}${url.informasi.instansi}?page=${page}`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'application/json',
      }),
    });
  },
  SubmitData: data => {
    return dispatch => {
      dispatch(fetchStart());
      var myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: data,
        redirect: 'follow',
      };
      return fetch(`${url.baseUrl}${url.uploader.submit}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: result.message };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: result.message };
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
};

export default uploadApi;
