import React from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Divider,
  Button,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    backgroundColor: '#F0F4F9',
    borderRadius: '4px',
    // height: '40px',
    width: '67%',
    border: 'none',
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  coverField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  container: {
    display: 'block',
    margin: '40px 250px',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 50px',
    },
    minHeight: '68vh',
  },
  textField: {
    wordWrap: 'nowarp',
  },
  radioClass: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  },
  buttonGroup: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
}));

const FormData = ({ BackToList, form, setForm, createAction, breadcrumbs, UpdateAction, errorForm }) => {
  const classes = useStyles();
  // const [form, setForm] = React.useState({
  //   fullname: '',
  //   email: '',
  //   phone_number: '',
  //   password: '',
  //   password_confirmation: '',
  //   status: 'Admin',
  // });
  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  return (
    <Box>
      <Typography>Add User Account</Typography>
      <Box className={classes.container}>
        <Box className={classes.coverField}>
          <Typography>Full Name</Typography>
          <TextField
            // fullWidth
            error={errorForm.fullname ? true : false}
            hiddenLabel
            onChange={handleChange}
            defaultValue={form.fullname}
            name="fullname"
            margin="dense"
            size="small"
            variant="filled"
            className={classes.textFieldRoot}
            placeholder="Enter Fullname Here"
            helperText={errorForm.fullname}
          />
        </Box>
        <Box className={classes.coverField}>
          <Typography>Email</Typography>
          <TextField
            // fullWidth
            error={errorForm.email ? true : false}
            hiddenLabel
            onChange={handleChange}
            defaultValue={form.email}
            name="email"
            margin="dense"
            size="small"
            variant="filled"
            className={classes.textFieldRoot}
            placeholder="Enter Email Here"
            helperText={errorForm.email}
          />
        </Box>
        <Box className={classes.coverField}>
          <Typography className={classes.textField}>Phone Number</Typography>
          <TextField
            // fullWidth
            error={errorForm.phone_number ? true : false}
            hiddenLabel
            onChange={handleChange}
            defaultValue={form.phone_number}
            name="phone_number"
            margin="dense"
            size="small"
            variant="filled"
            className={classes.textFieldRoot}
            placeholder="Enter phone number Here"
            helperText={errorForm.phone_number}
          />
        </Box>
        <Box className={classes.coverField}>
          <Typography>Password</Typography>
          <TextField
            // fullWidth
            error={errorForm.password ? true : false}
            hiddenLabel
            onChange={handleChange}
            //   defaultValue={email}
            name="password"
            margin="dense"
            type="password"
            size="small"
            variant="filled"
            className={classes.textFieldRoot}
            placeholder="Enter Password Here"
            helperText={errorForm.password}
          />
        </Box>
        <Box className={classes.coverField}>
          <Typography>Password Confirmation</Typography>
          <TextField
            // fullWidth
            error={errorForm.password_confirmation ? true : false}
            hiddenLabel
            onChange={handleChange}
            //   defaultValue={email}
            name="password_confirmation"
            type="password"
            margin="dense"
            size="small"
            variant="filled"
            className={classes.textFieldRoot}
            placeholder="Enter Password Confirmation Here"
            helperText={errorForm.password_confirmation}
          />
        </Box>
        <Box className={classes.coverField}>
          <Typography>Status</Typography>
          <FormControl component="fieldset" style={{ width: '67%' }}>
            <RadioGroup
              aria-label="status"
              name="status"
              value={form.status}
              onChange={handleChange}
              className={classes.radioClass}>
              <FormControlLabel value="Admin" control={<Radio name="status" />} label="Admin" />
              <FormControlLabel value="User" control={<Radio name="status" />} label="User " />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Divider className="Cmt-divider" orientation="horizontal" />
      <Box className={classes.buttonGroup}>
        <Button variant="contained" style={{ margin: '0px 10px 10px' }} onClick={BackToList}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: '0px 10px 10px' }}
          onClick={breadcrumbs[breadcrumbs.length - 1].mode === 'create' ? createAction : UpdateAction}>
          {breadcrumbs[breadcrumbs.length - 1].mode === 'create' ? 'Create' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
};

export default FormData;
