import { Container, InputForm, TextArea, WrapForm, Grid, Divider, ButtonSubmit, ButtonCancel, WrapButton } from 'component';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import cabangLoad from '../../../services/cabang';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles({
  errorForm: {
    display: 'block',
    fontSize: '10px',
    color: 'red',
  },
});

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Cabang', link: '/cabang' },
  { label: 'Tambah Data Cabang', isActive: true },
];
const Addcabang = () => {
  const classes = useStyles();
  const MySwal = withReactContent(Swal);
  const navigate = useHistory();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    kode: '',
    nama: '',
    alamat: '',
    kota: '',
    telp: '',
    ipaddress: '',
    userdb: '',
    passdb: '',
    namadb: '',
  });

  const [errorForm, setErrorForm] = useState({
    kode: null,
    nama: null,
    alamat: null,
    kota: null,
    telp: null,
    ipaddress: null,
    userdb: null,
    passdb: null,
    namadb: null,
  });

  const redirectTo = () => {
    navigate.push('/cabang');
  };
  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    const result = await dispatch(cabangLoad.add(form));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      });
      navigate.push('/cabang');
    } else {
      const msg = result.message;
      console.log('TESS', msg);
      setErrorForm({
        kode: msg?.kode ? msg.kode[0] : null,
        nama: msg?.nama ? msg.nama[0] : null,
        alamat: msg?.alamat ? msg.alamat[0] : null,
        kota: msg?.kota ? msg.kota[0] : null,
        telp: msg?.telp ? msg.telp[0] : null,
        ipaddress: msg?.ipaddress ? msg.ipaddress[0] : null,
        userdb: msg?.userdb ? msg.userdb[0] : null,
        passdb: msg?.passdb ? msg.passdb[0] : null,
        namadb: msg?.namadb ? msg.namadb[0] : null,
      });
    }
  };

  return (
    <Fragment>
      <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
        <Container>
          <h3>Tambah Data Cabang</h3>
          <Divider />
          <h3>Informasi Cabang</h3>
          <Grid />
          <WrapForm>
            <InputForm
              title="Kode Cabang"
              placeholder="Kode Cabang"
              width="50"
              onChange={handleChange}
              name="kode"
              error={errorForm.kode}
            />
            {/* {errorForm.kode && <span className={classes.errorForm}>{errorForm.kode}</span>} */}
            <InputForm
              title="Nama Cabang"
              placeholder="Nama Cabang"
              width="100"
              onChange={handleChange}
              name="nama"
              error={errorForm.nama}
            />
            {/* {errorForm.nama && <span className={classes.errorForm}>{errorForm.nama}</span>} */}
          </WrapForm>
          <Grid />
          <WrapForm>
            <TextArea
              error={errorForm.alamat}
              title="Alamat"
              placeholder="Alamat"
              width="100"
              onChange={handleChange}
              name="alamat"
            />
            {/* {errorForm.alamat && <span className={classes.errorForm}>{errorForm.alamat}</span>} */}
          </WrapForm>
          <Grid />
          <WrapForm>
            <InputForm
              title="Kota"
              placeholder="Kota"
              width="100"
              onChange={handleChange}
              name="kota"
              error={errorForm.kota}
            />
            {/* {errorForm.kota && <span className={classes.errorForm}>{errorForm.kota}</span>} */}
            <InputForm
              title="Telepon"
              placeholder="Telepon"
              width="100"
              onChange={handleChange}
              name="telp"
              error={errorForm.telp}
            />
            {/* {errorForm.telp && <span className={classes.errorForm}>{errorForm.telp}</span>} */}
          </WrapForm>
          <Grid />
          <Divider />
          <h3>Informasi Database</h3>
          <Grid />
          <WrapForm>
            <InputForm
              title="Nama Database"
              error={errorForm.namadb}
              placeholder="Nama Database"
              width="100"
              onChange={handleChange}
              name="namadb"
            />
            {/* {errorForm.namadb && <span className={classes.errorForm}>{errorForm.namadb}</span>} */}
            <InputForm
              title="User Database"
              placeholder="User Database"
              error={errorForm.userdb}
              width="100"
              onChange={handleChange}
              name="userdb"
            />
            {/* {errorForm.userdb && <span className={classes.errorForm}>{errorForm.userdb}</span>} */}
          </WrapForm>
          <Grid />
          <WrapForm>
            <InputForm
              title="Password Database"
              placeholder="Password Database"
              width="100"
              onChange={handleChange}
              name="passdb"
              type="password"
              error={errorForm.passdb}
            />
            {/* {errorForm.passdb && <span className={classes.errorForm}>{errorForm.passdb}</span>} */}
            <InputForm
              title="Ip Database"
              error={errorForm.ipaddress}
              placeholder="Ip Database"
              width="100"
              onChange={handleChange}
              name="ipaddress"
            />
            {/* {errorForm.ipaddress && <span className={classes.errorForm}>{errorForm.ipaddress}</span>} */}
          </WrapForm>

          <Grid />
          <Grid />
          <WrapButton>
            <ButtonCancel onClick={redirectTo} title={'Cancel'} />
            <ButtonSubmit title={'Simpan'} onClick={handleSave} />
          </WrapButton>
        </Container>
      </PageContainer>
    </Fragment>
  );
};

export default Addcabang;
