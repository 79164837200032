import React, { Fragment } from 'react';
import styled from 'styled-components';

const Titl = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-right: 12px;
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

const index = ({ title, children }) => {
  return (
    <Fragment>
      <Wrap>
        <Titl>{title}</Titl>
        {children}
      </Wrap>
    </Fragment>
  );
};

export default index;
