import React from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Table from './Table';
import { datas } from './sampleData';
import infromLoad from '../../../services/inform';
import { useDispatch, useSelector } from 'react-redux';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'informasi', isActive: true },
];

const Information = () => {
  const dispatch = useDispatch();
  const { activePaginate, keyword, InformasiData, pagination } = useSelector(state => state.inform);
  React.useEffect(() => {
    dispatch(infromLoad.getList());
  }, [dispatch, activePaginate, keyword]);
  return (
    <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
      <Table datas={InformasiData} pagination={pagination} />
    </PageContainer>
  );
};

export default Information;
