import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableData from './Table.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { setTabActive, setActivePricePagination, setKeywordPrice, setPriceLimit } from '../../../redux/actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: '25px',
  },
}));

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '20px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  select: {
    padding: '8px 12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: '#333',
    cursor: 'pointer',
    width: '200px',
  },
  // ... tambahkan properti CSS lainnya sesuai kebutuhan ...
};

export default function SimpleTabs() {
  const classes = useStyles();
  const { tabActive } = useSelector(state => state.price);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setActivePricePagination(1));
    dispatch(setTabActive(newValue));
    dispatch(setKeywordPrice(''));
    dispatch(setPriceLimit(10));
  };

  return (
    <div className={classes.root}>
      <Tabs value={tabActive} onChange={handleChange} aria-label="simple tabs" className={classes.TabsContainer}>
        <Tab label="Harga Umum" {...a11yProps(0)} />
        <Tab label="Harga Khusus" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tabActive} index={0}>
        <TableData />
      </TabPanel>
      <TabPanel value={tabActive} index={1}>
        <TableData />
      </TabPanel>
    </div>
  );
}
