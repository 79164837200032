import React, { Fragment, useRef, useState, useEffect } from 'react';
import CKEditor from 'react-ckeditor-component';
import './Information.css';
import Note from '@material-ui/icons/InsertDriveFile';
import Cloud from '@material-ui/icons/PictureAsPdfOutlined';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { Container, Grid, WrapForm, InputForm, WrapButton, ButtonCancel, ButtonSubmit } from './../../../component';
import TableAudience from './TableAudience';
// import { dataaudience } from './sampleDataAudience';
import { useHistory, useParams } from 'react-router';
import infromLoad from '../../../services/inform';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultStateInform, setKeywordInformasi, setActiveInfromastionPagination } from '../../../redux/actions/';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const defaultAudience = {
  personal: false,
  dokter: false,
  instansi: false,
};

const Editinformation = () => {
  const valueInput = useRef();
  const [content, SetContent] = useState('');
  const [files, setFiles] = useState([]);
  const [audience, setAudience] = useState(defaultAudience);
  const { id } = useParams();
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { activePaginate, keyword, InformasiData, pagination, activeLimit } = useSelector(state => state.inform);
  const [dataAudience, setDataAudience] = useState([]);
  const MySwal = withReactContent(Swal);

  const [judul, setJudul] = useState('');
  const [rowSelected, setRowSelected] = useState([]);
  const [errForm, setErrForm] = useState({
    judul: '',
    deskripsi: '',
    type_audience: '',
    filenames: '',
    kode_kontraktor: '',
  });
  const redirectTo = () => {
    dispatch(setKeywordInformasi(''));
    dispatch(setActiveInfromastionPagination(1));
    navigate.push('/informasi');
  };

  useEffect(() => {
    const arrAudience = dataAudience.map(item => item.kode);
    // dispatch(infromLoad.getListDokter());
    if (audience.instansi) {
      dispatch(infromLoad.getListInstansi(arrAudience));
    }
    if (audience.instansi === false) {
      dispatch(setDefaultStateInform());
    }
  }, [dispatch, audience, activePaginate, dataAudience, keyword, activeLimit]);

  useEffect(() => {
    const fetchData = async () => {
      const rest = await dispatch(infromLoad.GetDetail(id));
      console.log(rest, 'rest');
      if (rest.status === true) {
        const data = rest.data[0];
        setJudul(data.detail.judul);
        SetContent(data.detail.deskripsi);
        const types = data.types;

        setFiles(data.filename);
        let loadTypes = defaultAudience;
        types.forEach(element => {
          switch (element.type) {
            case '1': {
              loadTypes = { ...loadTypes, personal: true };
              break;
            }
            case '2': {
              loadTypes = { ...loadTypes, dokter: true };
              break;
            }
            case '3': {
              //GET USEEFFECT
              console.log('instansi');
              loadTypes = { ...loadTypes, instansi: true };
              if (data.audience) {
                // console.log(data.audience, 'masuk');
                setDataAudience(data.audience);
                const audienceId = data.audience.map(item => item.kode);
                setRowSelected(audienceId);
              }
              break;
            }
            default:
              loadTypes = defaultAudience;
          }
        });

        setAudience(loadTypes);
      }
    };

    fetchData();
  }, [dispatch, id]);

  const onChange = evt => {
    console.log('onChange fired with event info: ', evt);
    let newContent = evt.editor.getData();
    SetContent(newContent);
  };

  const onBlur = evt => {
    console.log('onBlur event called with event info: ', evt);
  };

  const afterPaste = evt => {
    console.log('afterPaste event called with event info: ', evt);
  };

  const handleSelected = e => {
    // console.log(e);
    if (e.length > 0) {
      setRowSelected(e);
    } else {
      setRowSelected(null);
    }
  };

  const handleUpload = e => {
    const newFiles = e.target.files[0];
    if (e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onloadend = d => {
        setFiles([newFiles, ...files]);
      };
      reader.readAsDataURL(newFiles);
    }
    valueInput.current.value = '';
  };

  const onRemove = index => {
    let newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
    valueInput.current.value = '';
  };

  const handleAudience = e => {
    const { name, checked } = e.target;
    setAudience({ ...audience, [name]: checked });
  };

  const handleSave = async () => {
    let data = new FormData();
    const kode = rowSelected;
    data.append('id_pengumuman', id);
    data.append('judul', judul);
    data.append('deskripsi', content);
    let indexCount = 0;
    if (audience.personal) {
      data.append(`type_audience[${indexCount}]`, 1);
      indexCount++;
    }

    if (audience.dokter) {
      data.append(`type_audience[${indexCount}]`, 2);
      indexCount++;
    }

    if (audience.instansi) {
      data.append(`type_audience[${indexCount}]`, 3);
      indexCount++;
    }
    // console.log('files', files);
    for (let i = 0; i < files.length; i++) {
      // console.log('files', files[i].name ? 'name' : 'fileName');
      if (files[i].name) {
        data.append('filenames[]', files[i]);
      }
    }

    if (audience.instansi && Array.isArray(kode)) {
      for (let index = 0; index < kode.length; index++) {
        data.append(`kode_kontraktor[${index}]`, kode[index]);
      }
    }
    const result = await dispatch(infromLoad.UpdateDetail(data));
    // console.log(result);
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        redirectTo();
        return true;
      });
    } else {
      const msg = result.message;
      console.log(msg.judul);
      if (msg.judul) {
        setErrForm({ ...errForm, judul: msg.judul[0] });
      }
      if (msg.deskripsi) {
        setErrForm({ ...errForm, deskripsi: msg.deskripsi[0] });
      }
      if (msg.type_audience) {
        setErrForm({ ...errForm, type_audience: msg.type_audience[0] });
      }
      if (msg['filenames.0']) {
        setErrForm({ ...errForm, filenames: msg['filenames.0'] });
      }
      if (msg.kode_kontraktor) {
        setErrForm({ ...errForm, kode_kontraktor: msg.kode_kontraktor[0] });
      }
    }
  };
  return (
    <Fragment>
      <Container height={'fit-content'}>
        <h3>Edit Information</h3>
        <Grid />
        <WrapForm>
          <InputForm
            title="Judul"
            placeholder="Judul"
            width="100"
            alignTitle="left"
            onChange={e => setJudul(e.target.value)}
            value={judul}
          />
          {errForm.judul && <span style={{ color: 'red' }}>{errForm.judul}</span>}
        </WrapForm>
        <Grid />
        <div className="ckeditor-wrap">
          <p style={{ minWidth: 150 }}>Deskripsi</p>
          <CKEditor
            activeClass="testingstyle"
            content={content}
            events={{
              blur: onBlur,
              afterPaste: afterPaste,
              change: onChange,
            }}
          />
          {errForm.deskripsi && <span style={{ color: 'red' }}>{errForm.deskripsi}</span>}
        </div>
      </Container>
      <Grid />
      <Container height={'fit-content'}>
        <div className="wrap-uploadfile">
          <h3>Upload Files</h3>
          <div className="file file--upload">
            <label htmlFor="input-file">
              <Cloud style={{ marginRight: 6 }} fontSize="small" /> Upload File Pdf
            </label>

            <input id="input-file" type="file" multiple onChange={handleUpload} ref={valueInput} accept="application/pdf" />
            {errForm.judul && <span style={{ color: 'red' }}>{errForm.judul}</span>}
          </div>
        </div>

        <div>
          {files.map((data_file, index) => {
            return (
              <div key={index} className="list-upload">
                <div style={{ marginRight: 6 }}>
                  <Note fontSize="small" />
                </div>
                <p>{files[index].name || files[index].file_name}</p>
                <div style={{ cursor: 'pointer' }} onClick={() => onRemove(index)}>
                  <RemoveCircle fontSize="small" />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
      <Grid />
      <Container height={'fit-content'}>
        <h3>Audience</h3>
        <Grid />

        <div className="wrap-audience">
          <div className={`box-selected-audience ${audience.personal ? 'active' : null}`}>
            <label htmlFor="personal">Personal</label>
            <input
              name="personal"
              id="personal"
              type="checkbox"
              value={audience.personal}
              onChange={handleAudience}
              checked={audience.personal}
            />
          </div>
          <div className={`box-selected-audience ${audience.dokter ? 'active' : null}`}>
            <label htmlFor="dokter">Dokter</label>
            <input
              name="dokter"
              id="dokter"
              type="checkbox"
              value={audience.dokter}
              onChange={handleAudience}
              checked={audience.dokter}
            />
          </div>
          <div className={`box-selected-audience ${audience.instansi ? 'active' : null}`}>
            <label htmlFor="instansi">Instansi</label>
            <input
              name="instansi"
              id="instansi"
              type="checkbox"
              value={audience.instansi}
              onChange={handleAudience}
              checked={audience.instansi}
            />
          </div>
        </div>
        {errForm.type_audience && <span style={{ color: 'red' }}>{errForm.type_audience}</span>}
        {audience.instansi && (
          <TableAudience
            datas={InformasiData}
            pagination={pagination}
            handleSelected={e => handleSelected(e)}
            dataAudience={dataAudience}
            activePaginate={activePaginate}
            setSelected={e => setRowSelected(e)}
            selected={rowSelected}
          />
        )}
      </Container>
      <Grid />
      <WrapButton>
        <ButtonCancel onClick={redirectTo} title={'Cancel'} />
        <ButtonSubmit title={'Simpan'} onClick={handleSave} />
      </WrapButton>
    </Fragment>
  );
};

export default Editinformation;
