import styled from 'styled-components';

import React from 'react';

const Wrap = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  /* height: 75vh; */
  height: ${props => (props.height ? props.height : '75vh')};
  overflow-y: auto;
  position: relative;
`;

const Container = ({ children, height }) => {
  return <Wrap height={height}>{children}</Wrap>;
};

export default Container;
