import React, { useContext } from 'react';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Logo from './Logo';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 16px 0px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
    marginBottom: '12px',
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 0',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  return (
    <div className={classes.root}>
      {/* <CmtAvatar src={'https://via.placeholder.com/150'} alt="User Avatar" />
      <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userTitle} component="h3" variant="h6">
              Robert Johnson
            </Typography>
            <Typography className={classes.userSubTitle}>robert.johnson@gmail.com</Typography>
          </div>
          <ArrowDropDownIcon />
        </div>
      </div> */}
      <Logo color="white" className={classes.logo} />
    </div>
  );
};

export default SidebarHeader;
