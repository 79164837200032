import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import PlusOne from '@material-ui/icons/Add';
import TableData from './Table';
import FormData from './FormData';
import { lighten, makeStyles, alpha } from '@material-ui/core/styles';
import adminLoad from '../../../services/user';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveAdminPagination, setKeywordAdmin } from '../../../redux/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
      margin: theme.spacing(2),
      width: theme.spacing(32),
      height: theme.spacing(32),
    },
  },
  paperOne: {
    width: '91vw',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  HeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirections: 'row',
      flexWrap: 'wrap',
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginRight: '5px',
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F0F4F9',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(220, 220, 220, 0.5)',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  paging: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent !important',
    height: 'auto',
    '& > *': {
      marginTop: theme.spacing(4),
      backgroundColor: 'transparent !important',
    },
  },
  group: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    margin: theme.spacing(1),
    width: '170px',
    height: '36px',
    borderRadius: '4px',
    backgroundColor: '#FDE6E6',
    color: '#F24141',
    '&:hover': {
      backgroundColor: '#F24141',
      color: '#FFFFFF',
    },
  },
}));

const breadcrumb = [
  { label: 'Account', link: '/' },
  { label: 'user', isActive: true },
];

const Price = () => {
  const classes = useStyles();
  const [breadcrumbs, setBreadcrumbs] = React.useState(breadcrumb);
  const dispatch = useDispatch();
  const { pagination, activePaginate, keyword } = useSelector(state => state.user);
  const MySwal = withReactContent(Swal);

  const [form, setForm] = React.useState({
    id: null,
    fullname: '',
    email: '',
    phone_number: '',
    password: '',
    password_confirmation: '',
    status: 'Admin',
  });

  const [searchKeyword, setKeyword] = React.useState('');
  const [errorForm, setErrorForm] = React.useState({
    fullname: null,
    email: null,
    phone_number: null,
    password: null,
    password_confirmation: null,
  });

  React.useEffect(() => {
    dispatch(adminLoad.getList());
  }, [dispatch, activePaginate, keyword]);

  const NewContactAction = () => {
    setBreadcrumbs([...breadcrumb, { label: 'New Contact', isActive: true, mode: 'create' }]);
  };

  const BackToList = () => {
    setBreadcrumbs(breadcrumb);
    dispatch(setActiveAdminPagination(1));
    dispatch(setKeywordAdmin(''));
    dispatch(adminLoad.getList());
    setForm({
      id: null,
      fullname: '',
      email: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
      status: 'Admin',
    });

    setErrorForm({
      fullname: null,
      email: null,
      phone_number: null,
      password: null,
      password_confirmation: null,
    });
  };

  const HandlePagination = (event, current_page) => {
    dispatch(setActiveAdminPagination(current_page));
  };

  const apiDeleteHandler = async id => {
    const result = await dispatch(adminLoad.deleteUser({ id }));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      });
      dispatch(adminLoad.getList());
    }
  };

  const deleteUser = id => {
    MySwal.fire({
      title: 'Do you want to Delete this user?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        apiDeleteHandler(id);
      }
    });
  };

  const createNew = async () => {
    const result = await dispatch(adminLoad.addUser(form));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        BackToList();
        return true;
      });
    } else {
      const msg = result.message;
      setErrorForm({
        fullname: msg?.fullname ? msg.fullname[0] : null,
        email: msg?.email ? msg.email[0] : null,
        phone_number: msg?.phone_number ? msg.phone_number[0] : null,
        password: msg?.password ? msg.password[0] : null,
        password_confirmation: msg?.password_confirmation ? msg.password_confirmation[0] : null,
      });
    }
  };

  const editAction = async id => {
    const result = await dispatch(adminLoad.GetDetail(id));
    if (result.status === true) {
      setForm({
        id: result.data.id,
        fullname: result.data.fullname,
        email: result.data.email,
        phone_number: result.data.phone_number,
        password: '',
        password_confirmation: '',
        status: result.data.status,
      });
      setBreadcrumbs([...breadcrumb, { label: 'Edit Contact', isActive: true, mode: 'edit' }]);
    }
  };

  const searchAction = () => {
    dispatch(setKeywordAdmin(searchKeyword));
  };

  const UpdateAction = async () => {
    let request;
    if (form.password !== '') {
      request = {
        id: form.id,
        fullname: form.fullname,
        email: form.email,
        phone_number: form.phone_number,
        password: form.password,
        password_confirmation: form.password_confirmation,
        status: form.status,
      };
    } else {
      request = {
        id: form.id,
        fullname: form.fullname,
        email: form.email,
        phone_number: form.phone_number,
        status: form.status,
      };
    }

    const result = await dispatch(adminLoad.UpdateDetail(request));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      });
      BackToList();
    } else {
      const msg = result.message;
      setErrorForm({
        fullname: msg?.fullname ? msg.fullname[0] : null,
        email: msg?.email ? msg.email[0] : null,
        phone_number: msg?.phone_number ? msg.phone_number[0] : null,
        password: msg?.password ? msg.password[0] : null,
        password_confirmation: msg?.password_confirmation ? msg.password_confirmation[0] : null,
      });
    }
  };

  return (
    <PageContainer heading="Account" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box className={classes.root}>
            <Paper elevation={2} className={classes.paperOne}>
              {breadcrumbs[breadcrumbs.length - 1].label === 'user' ? (
                <Box>
                  <Box className={classes.HeaderContainer}>
                    <Typography style={{ fontSize: '16px', fontWeight: '600' }} gutterBottom>
                      Account
                    </Typography>
                    <Box className={classes.group}>
                      <Box className={classes.searchContainer}>
                        <div className={classes.search}>
                          <InputBase
                            placeholder="Search…"
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={e => setKeyword(e.target.value)}
                          />
                        </div>
                        <div className={classes.searchIcon}>
                          <IconButton aria-label="search" className={classes.margin} size="small" onClick={searchAction}>
                            <SearchIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </Box>
                      <Button
                        variant="contained"
                        className={classes.button}
                        startIcon={<PlusOne />}
                        size="small"
                        onClick={NewContactAction}>
                        <Typography style={{ fontSize: '13px' }}>New Contact</Typography>
                      </Button>
                    </Box>
                  </Box>
                  <TableData editAction={editAction} deleteAction={deleteUser} />
                </Box>
              ) : (
                <FormData
                  BackToList={BackToList}
                  form={form}
                  setForm={setForm}
                  createAction={createNew}
                  breadcrumbs={breadcrumbs}
                  UpdateAction={UpdateAction}
                  errorForm={errorForm}
                />
              )}
            </Paper>
            {breadcrumbs[breadcrumbs.length - 1].label === 'user' && (
              <Box className={classes.paging}>
                <Pagination
                  count={pagination.last_page}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                  page={activePaginate}
                  onChange={HandlePagination}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Price;
