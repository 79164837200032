import React, { Fragment } from 'react';
import styled from 'styled-components';
const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${props => (props.width ? props.width : '10')}%;
  p {
    padding-right: 12px;
    min-width: 150px;
    text-align: right;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    p {
      padding-right: 12px;
      min-width: 150px;
      text-align: left;
    }
  }
`;

const Textarea = styled.textarea`
  background-color: #f0f4f9;
  display: flex;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 12px;
  width: ${props => (props.width ? props.width : '10')}%;
`;

const Err = styled.p`
  font-size: 14px;
  color: #dc3545;
  padding-top: 4px;
  text-align: left !important;
`;

const TextArea = ({ title = 'name', width, error, ...props }) => {
  return (
    <Fragment>
      <Wrap width={width}>
        <p>{title}</p>
        <div style={{ width: '100%' }}>
          <Textarea rows="5" width={width} type="text" {...props} />
          {error ? <Err>{error}</Err> : null}
        </div>
      </Wrap>
    </Fragment>
  );
};

export default TextArea;
