import React, { Fragment } from 'react';
import { Styles, useStyles, PaginStyle } from './style';
import { Container } from './../../../component';
import { useTable, usePagination } from 'react-table';
import Paginations from '@material-ui/lab/Pagination';
import { ButtonDelete, ButtonEdit, WrapTable } from 'component';
import { Input, Button, Grid } from './../../../component';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import informLoad from '../../../services/inform';
import { setKeywordInformasi } from '../../../redux/actions';
import { setActiveInfromastionPagination } from '../../../redux/actions/';

const Table = ({ columns, data, pagination }) => {
  const [keyword, setKeyword] = React.useState('');
  const dispatch = useDispatch();

  const changeKeyword = () => {
    dispatch(setKeywordInformasi(keyword));
  };
  const navigate = useHistory();

  const redirectTo = () => {
    navigate.push('/add-information');
  };
  const classes = useStyles();
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, gotoPage, pageCount } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  return (
    <Fragment>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Input
            spacing={'12px'}
            placeholder="Search..."
            onChange={e => setKeyword(e.target.value)}
            onClick={changeKeyword}
          />
          <Button title={'New Information'} onClick={redirectTo}>
            <AddIcon fontSize="small" />
          </Button>
        </div>

        <Grid />
        <WrapTable>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ height: '100%' }}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </WrapTable>
      </Container>
      <Grid />
      <PaginStyle>
        <Paginations
          className={classes.ul}
          count={pagination.last_page}
          variant="outlined"
          shape="rounded"
          onChange={(d, i) => {
            dispatch(setActiveInfromastionPagination(i));
            gotoPage(i - 1);
          }}
        />
      </PaginStyle>
    </Fragment>
  );
};

function App({ datas, pagination }) {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const apiDeleteHandler = async kode => {
    const result = await dispatch(informLoad.delete({ id_pengumuman: kode }));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      });
      dispatch(informLoad.getList());
    }
  };

  const deleteAction = id => {
    MySwal.fire({
      title: 'Do you want to Delete this user?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        apiDeleteHandler(id);
      }
    });
  };

  const redirectToEdit = id => {
    navigate.push(`/edit-information/${id}`);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Deskripsi',
        accessor: 'deskripsi',
        Cell: ({ row }) => <div dangerouslySetInnerHTML={{ __html: row.original.deskripsi }} />,
      },
      {
        Header: 'Audience',
        accessor: 'type_audience',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: d => (
          <div style={{ display: 'flex' }}>
            <ButtonEdit onClick={() => redirectToEdit(d.row.original.id_pengumuman)} />
            <ButtonDelete onClick={() => deleteAction(d.row.original.id_pengumuman)} />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Styles>
      <Table columns={columns} data={datas} pagination={pagination} />
    </Styles>
  );
}

export default App;
