import {
  LOAD_DATA_PRICE,
  SET_PRICE_DATA,
  TAB_ACTIVE,
  SET_PRICE_PAGINATION,
  SET_ACTIVE_PRICE_PAGINATION,
  SET_KEYWORD_PRICE,
  SET_PRICE_LIMIT,
  SET_SEARCH_MODE,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  loadPrice: false,
  priceData: [],
  tabActive: 0,
  pagination: {
    current_page: 0,
    per_page: 0,
    total: 0,
    last_page: 0,
    from: 0,
  },
  activePaginate: 1,
  keyword: '',
  limit: 10,
  searchMode: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_DATA_PRICE: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SET_PRICE_DATA: {
      return {
        ...state,
        priceData: action.payload,
      };
    }
    case TAB_ACTIVE: {
      return {
        ...state,
        tabActive: action.payload,
      };
    }
    case SET_PRICE_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case SET_ACTIVE_PRICE_PAGINATION: {
      return {
        ...state,
        activePaginate: action.payload,
      };
    }
    case SET_KEYWORD_PRICE: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    case SET_PRICE_LIMIT: {
      return {
        ...state,
        limit: action.payload,
      };
    }
    case SET_SEARCH_MODE: {
      return {
        ...state,
        searchMode: action.payload,
      };
    }
    default:
      return state;
  }
};
