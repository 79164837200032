import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { getInfromasiList, setInfrormasiPagination } from '../../redux/actions';
import _ from 'lodash';

const InformLoad = {
  getList: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword } = getState().inform;
      dispatch(fetchStart());
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.informasi.list_search}?keyword=${keyword}`;
      } else {
        endpoin = `${url.informasi.list}?page=${activePaginate}`;
      }

      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            if (data.success) {
              dispatch(fetchSuccess());
              const result = data.informasi.data;
              dispatch(getInfromasiList(result));
              dispatch(
                setInfrormasiPagination({
                  current_page: data.informasi.current_page,
                  per_page: data.informasi.per_page,
                  total: data.informasi.total,
                  last_page: data.informasi.last_page,
                  from: data.informasi.from,
                }),
              );
            } else {
              dispatch(fetchError(data.message.keyword[0]));
            }
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  getListPersonal: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword } = getState().inform;
      dispatch(fetchStart());
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.informasi.personal_search}?keyword=${keyword}`;
      } else {
        endpoin = `${url.informasi.personal}?page=${activePaginate}`;
      }

      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            const result = data.personal.data;
            dispatch(getInfromasiList(result));
            dispatch(
              setInfrormasiPagination({
                current_page: data.personal.current_page,
                per_page: data.personal.per_page,
                total: data.personal.total,
                last_page: data.personal.last_page,
                from: data.personal.from,
              }),
            );
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  getListDokter: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword } = getState().inform;
      dispatch(fetchStart());
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.informasi.doktor_search}?keyword=${keyword}`;
      } else {
        endpoin = `${url.informasi.doktor}?page=${activePaginate}`;
      }

      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            const result = data.doktor.data;
            dispatch(getInfromasiList(result));
            dispatch(
              setInfrormasiPagination({
                current_page: data.doktor.current_page,
                per_page: data.doktor.per_page,
                total: data.doktor.total,
                last_page: data.doktor.last_page,
                from: data.doktor.from,
              }),
            );
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  getListInstansi: (dataAudience = null) => {
    return (dispatch, getState) => {
      const { activePaginate, keyword, activeLimit } = getState().inform;
      if (dataAudience == null) {
        dispatch(fetchStart());
        let endpoin;
        if (keyword !== '') {
          endpoin = `${url.informasi.instansi_search}?keyword=${keyword}`;
        } else {
          endpoin = `${url.informasi.instansi}?page=${activePaginate}&limit=${activeLimit}`;
        }
        axios
          .get(endpoin)
          .then(({ data, status }) => {
            if (status === 200) {
              dispatch(fetchSuccess());
              const result = data.instansi.data;
              dispatch(getInfromasiList(result));
              dispatch(
                setInfrormasiPagination({
                  current_page: data.instansi.current_page,
                  per_page: data.instansi.per_page,
                  total: data.instansi.total,
                  last_page: data.instansi.last_page,
                  from: data.instansi.from,
                }),
              );
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } else {
        const { activePaginate, keyword } = getState().inform;
        dispatch(fetchStart());
        let endpoin;
        if (keyword !== '') {
          endpoin = `${url.informasi.instansi_search}?keyword=${keyword}`;
        } else {
          endpoin = `${url.informasi.instansi}?page=${activePaginate}&limit=${activeLimit}`;
        }

        axios
          .get(endpoin)
          .then(({ data, status }) => {
            if (status === 200) {
              dispatch(fetchSuccess());
              const result = data.instansi.data;
              dispatch(getInfromasiList(result));
              dispatch(
                setInfrormasiPagination({
                  current_page: data.instansi.current_page,
                  per_page: data.instansi.per_page,
                  total: data.instansi.total,
                  last_page: data.instansi.last_page,
                  from: data.instansi.from,
                }),
              );
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      }
    };
  },

  add: data => {
    return dispatch => {
      dispatch(fetchStart());
      var myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: data,
        redirect: 'follow',
      };
      return fetch(`${url.baseUrl}${url.informasi.add}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: result.message };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: result.message };
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
      // return axios
      //   .post(`${url.informasi.add}`, data, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //     },
      //   })
      //   .then(({ data, status }) => {
      //     if (status === 200 && data.success === true) {
      //       dispatch(fetchSuccess());
      //       return { status: true, message: data.message };
      //     } else {
      //       dispatch(fetchSuccess());
      //       return { status: false, message: data.message };
      //     }
      //   })
      //   .catch(function(error) {
      //     dispatch(fetchError(error.message));
      //     return { status: false, message: error.message };
      //   });
    };
  },
  delete: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(`${url.informasi.delete}`, id)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            fetchError(data.message);
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
  GetDetail: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .get(`${url.informasi.detail}?kode=${id}`)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return {
              status: true,
              message: data.message,
              data: data.informasidetail,
            };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message, data: [] };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message, data: [] };
        });
    };
  },
  UpdateDetail: data => {
    return dispatch => {
      dispatch(fetchStart());
      var myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('accessToken'));
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: data,
        redirect: 'follow',
      };
      return fetch(`${url.baseUrl}${url.informasi.update}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: result.message };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: result.message };
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
};

export default InformLoad;
