import React, { useEffect } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Box, Typography, Switch } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TabSimple from './Tabs.jsx';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import priceLoad from '../../../services/price';
import {
  setActivePricePagination,
  setKeywordPrice,
  setPriceLimit,
  setSearchMode,
  setTabActive,
} from '../../../redux/actions';
import TableData from './Table.jsx';
import { Button } from 'component';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
      margin: theme.spacing(2),
      width: theme.spacing(32),
      height: theme.spacing(32),
    },
  },
  paperOne: {
    width: '91vw',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  HeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formControl: {
    '.MuiFormControlLabel-label': {
      fontSize: '12px',
    },
  },
  paging: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent !important',
    height: 'auto',
    '& > *': {
      marginTop: theme.spacing(4),
      backgroundColor: 'transparent !important',
    },
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'harga', isActive: true },
];

const Price = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedToggle: false,
  });

  const { tabActive, pagination, activePaginate, keyword, limit } = useSelector(state => state.price);
  const dispatch = useDispatch();

  //reset tabActive ,activePaginate, keyword, limit when change page
  useEffect(() => {
    dispatch(setActivePricePagination(1));
    dispatch(setKeywordPrice(''));
    dispatch(setSearchMode(false));
    dispatch(setTabActive(0));
  }, [dispatch]);

  useEffect(() => {
    if (tabActive === 0 && state.checkedToggle === false) {
      dispatch(priceLoad.getUmum());
    } else if (tabActive === 1 && state.checkedToggle === false) {
      dispatch(priceLoad.getKhusus());
    } else {
      dispatch(priceLoad.getShowHide());
    }
  }, [dispatch, tabActive, activePaginate, state.checkedToggle, keyword, limit]);

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    dispatch(setActivePricePagination(1));
    dispatch(setKeywordPrice(''));
  };

  const HandlePagination = (event, current_page) => {
    dispatch(setActivePricePagination(current_page));
  };

  const HandleRowPagination = (event, current_page) => {
    console.log(event.target.value);
    dispatch(setPriceLimit(event.target.value));
  };

  return (
    <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box className={classes.root}>
            <Paper elevation={2} className={classes.paperOne}>
              <Box className={classes.HeaderContainer}>
                <Typography variant="h6" gutterBottom>
                  Daftar Harga
                </Typography>
                <Box>
                  <FormControlLabel
                    control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedToggle" />}
                    label={<Typography variant="h6">Sembunyikan Harga</Typography>}
                    className={classes.fromControl}
                  />
                </Box>
              </Box>
              <Box>
                {state.checkedToggle === false && <TabSimple />}
                {state.checkedToggle === true && (
                  <TableData
                    toggle={state.checkedToggle}
                    last_page={pagination.total}
                    page={activePaginate}
                    HandlePagination={(e, c) => HandlePagination(e, c)}
                    HandleRowPagination={(e, c) => HandleRowPagination(e, c)}
                    limit={limit}
                  />
                )}
              </Box>
            </Paper>
            {state.checkedToggle === false && (
              <Box className={classes.paging}>
                <Pagination
                  count={pagination.last_page}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                  page={activePaginate}
                  onChange={HandlePagination}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Price;
