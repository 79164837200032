import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import React from 'react';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
// import { setActiveCabangPagination, setKeywordCabang } from '../../../redux/actions';
import cabangLoad from '../../../services/cabang';

// import { datas } from './sampleData';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Cabang', isActive: true },
];

const Cabang = () => {
  const dispatch = useDispatch();
  const { pagination, activePaginate, keyword, cabangData } = useSelector(state => state.cabang);
  const MySwal = withReactContent(Swal);
  React.useEffect(() => {
    dispatch(cabangLoad.getList());
  }, [dispatch, activePaginate, keyword]);

  const apiDeleteHandler = async kode => {
    const result = await dispatch(cabangLoad.delete({ kode }));
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      });
      dispatch(cabangLoad.getList());
    }
  };

  const deleteAction = id => {
    MySwal.fire({
      title: 'Do you want to Delete this user?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        apiDeleteHandler(id);
      }
    });
  };

  return (
    <PageContainer heading="Dashboard" breadcrumbs={breadcrumbs}>
      <Table datas={cabangData} deleteAction={deleteAction} />
    </PageContainer>
  );
};

export default Cabang;
