import axios from '@jumbo/utils/axiosHelper';
import axiost from 'axios';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { getAdminList, setAdminPagination } from '../../redux/actions';

let cancelToken;

const adminLoad = {
  getList: () => {
    return (dispatch, getState) => {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelToken = axiost.CancelToken.source();
      const { activePaginate, keyword } = getState().user;
      dispatch(fetchStart());
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.user.list}?keyword=${keyword}`;
      } else {
        endpoin = `${url.user.list}?page=${activePaginate}`;
      }

      axios
        .get(endpoin, { cancelToken: cancelToken.token })
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            dispatch(getAdminList(data.admin.data));
            dispatch(
              setAdminPagination({
                current_page: data.admin.current_page,
                per_page: data.admin.per_page,
                total: data.admin.total,
                last_page: data.admin.last_page,
                from: data.admin.from,
              }),
            );
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  addUser: data => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(`${url.user.add}`, data)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
  deleteUser: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .post(`${url.user.delete}`, id)
        .then(({ data, status }) => {
          if (status === 201) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            fetchError(data.message);
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
  GetDetail: id => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .get(`${url.user.detail}/${id}`)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message, data: data.admin };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message, data: [] };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message, data: [] };
        });
    };
  },
  UpdateDetail: params => {
    return dispatch => {
      dispatch(fetchStart());
      return axios
        .put(`${url.user.update}`, params)
        .then(({ data, status }) => {
          if ((status === 200 || status === 201) && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message, data: data };
          } else {
            dispatch(fetchSuccess());
            return { status: false, message: data.message, data: [] };
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          return { status: false, message: error.message, data: [] };
        });
    };
  },
};

export default adminLoad;
