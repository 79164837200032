import {
  SET_CABANG_DATA,
  SET_ACTIVE_CABANG_PAGINATION,
  SET_CABANG_PAGINATION,
  SET_CABANG_KEYWORD,
  LOAD_DATA_CABANG,
} from '../../@jumbo/constants/ActionTypes';

export const getCabangList = data => {
  return dispatch => {
    dispatch({
      type: SET_CABANG_DATA,
      payload: data,
    });
  };
};

export const setActiveCabangPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_CABANG_PAGINATION,
      payload: pagination,
    });
  };
};

export const setCabangPagination = pagination => {
  return dispatch => {
    dispatch({
      type: SET_CABANG_PAGINATION,
      payload: pagination,
    });
  };
};

export const setKeywordCabang = keyword => {
  return dispatch => {
    dispatch({
      type: SET_CABANG_KEYWORD,
      payload: keyword,
    });
  };
};
