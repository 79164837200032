import React, { Fragment } from 'react';
import EditIcon from '@material-ui/icons/EditTwoTone';
import { BtnAction } from './style';

const Buttonedit = ({ ...props }) => {
  return (
    <Fragment>
      <BtnAction {...props}>
        <EditIcon fontSize="small" />
      </BtnAction>
    </Fragment>
  );
};

export default Buttonedit;
